// // // // // import React, { useState } from 'react';
// // // // // import './BookingForm.css';

// // // // // const BookingForm = () => {
// // // // //     const [formData, setFormData] = useState({
// // // // //         hotelCode: 'MAU',
// // // // //         dateDeparture: '',
// // // // //         dateReturn: '',
// // // // //         departureCity: 'JOHANNESBURG',
// // // // //         includeAir: true,
// // // // //         flightAdvanced: 'All Airlines',
// // // // //         flightCabin: 'Cheapest',
// // // // //         honeymoonRates: false,
// // // // //         repeaterRates: false,
// // // // //         weddingRates: false,
// // // // //         nbrAdults: '2',
// // // // //         nbrChildren: '0',
// // // // //         nbrInfants: '0'
// // // // //     });
// // // // //     const [apiResponse, setApiResponse] = useState(null);
// // // // //     const [selectedImages, setSelectedImages] = useState([]);
// // // // //     const [isGalleryOpen, setGalleryOpen] = useState(false);
// // // // //     const [loading, setLoading] = useState(false); // Loading state for API request
// // // // //     const [showTandCModal, setShowTandCModal] = useState(false);
// // // // //     const [selectedPackage, setSelectedPackage] = useState(null);

// // // // //     const handleInputChange = (e) => {
// // // // //         const { name, value, type, checked } = e.target;
// // // // //         setFormData((prevData) => ({
// // // // //             ...prevData,
// // // // //             [name]: type === 'checkbox' ? checked : value
// // // // //         }));
// // // // //     };

// // // // //     const handleFormSubmit = async (e) => {
// // // // //         e.preventDefault();
// // // // //         setLoading(true); // Set loading to true when Get Quote is clicked
// // // // //         setApiResponse(null); // Reset API response before new request

// // // // //         const payload = {
// // // // //             ...formData,
// // // // //             dateDeparture: new Date(formData.dateDeparture).toISOString(),
// // // // //             dateReturn: new Date(formData.dateReturn).toISOString(),
// // // // //         };
        
// // // // //         console.log('Sending request to Beachcomber API with payload:', payload);
// // // // //         try {
// // // // //             const response = await fetch('/api/getQuote', {
// // // // //                 method: 'POST',
// // // // //                 headers: {
// // // // //                     'Content-Type': 'application/json',
// // // // //                     'BeachcomberKey': 'YOUR_API_KEY_HERE'
// // // // //                 },
// // // // //                 body: JSON.stringify(payload)
// // // // //             });

// // // // //             if (!response.ok) {
// // // // //                 throw new Error('Failed to fetch room options');
// // // // //             }

// // // // //             const data = await response.json();
// // // // //             setApiResponse(data);
// // // // //         } catch (error) {
// // // // //             console.error('Error during API request:', error);
// // // // //         } finally {
// // // // //             setLoading(false); // Set loading to false when request is complete
// // // // //         }
// // // // //     };

// // // // //     const handleImageClick = (images) => {
// // // // //         setSelectedImages(images);
// // // // //         setGalleryOpen(true);
// // // // //     };

// // // // //     const closeGallery = () => {
// // // // //         setGalleryOpen(false);
// // // // //     };

// // // // //     const handleGetAnotherQuote = () => {
// // // // //         setApiResponse(null);
// // // // //         setFormData({
// // // // //             hotelCode: 'MAU',
// // // // //             dateDeparture: '',
// // // // //             dateReturn: '',
// // // // //             departureCity: 'JOHANNESBURG',
// // // // //             includeAir: true,
// // // // //             flightAdvanced: 'All Airlines',
// // // // //             flightCabin: 'Cheapest',
// // // // //             honeymoonRates: false,
// // // // //             repeaterRates: false,
// // // // //             weddingRates: false,
// // // // //             nbrAdults: '2',
// // // // //             nbrChildren: '0',
// // // // //             nbrInfants: '0'
// // // // //         });
// // // // //     };

// // // // //     const formatCurrency = (amount) => {
// // // // //         return `R${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;
// // // // //     };

// // // // //     // Full Terms and Conditions text
// // // // //     const termsAndConditions = `
// // // // // For foreigners:

// // // // // While we strive to always provide the latest information and details about our product offerings on this website, please be sure to check with the hotel on any specifics that may be important to you. Information presented here is subject to change. Contents of this website only apply to Beachcomber Resorts & Hotels in Mauritius.

// // // // // Room Disclaimer:
// // // // // Beachcomber carries out regular improvements to its existing accommodation and therefore room facilities or room layout of the reserved room may be different from the displayed photo.

// // // // // Check in Check out:
// // // // // Check-in: 2 p.m.
// // // // // Check-out: noon
// // // // // For an early check-in or late check-out after 6.00 p.m., one full night will be charged.
// // // // // Rate charged will be based on meal plan booked and offers.
// // // // // For a late check-out until 6.00 p.m. (on request), 50% of the applicable rate will be charged – except for Royal Palm Beachcomber Luxury, where one full night will be charged for a pre-bookings and late check-outs.

// // // // // For nights falling between 20 December 2024 and 8 January 2025
// // // // // One full night will be charged for pre-bookings and late check-outs
// // // // // For Villas at Dinarobin Beachcomber, Paradis Beachcomber and Trou aux Biches Beachcomber, early check-in and late check-out are granted without any supplement

// // // // // Honeymoon Policy:
// // // // // Valid 12 months as from the wedding date
// // // // // Your wedding certificate will be requested at the time of check-in
// // // // // Should you fail to produce your valid wedding certificate upon arrival, an extra cost will be charged directly at the hotel

// // // // // Wedding Anniversary Policy:
// // // // // The anniversary date should be within 6 months prior to the date of arrival or 6 months after the date of arrival
// // // // // Offer applicable to every 5th wedding anniversary
// // // // // Your wedding certificate will be requested at the time of check-in
// // // // // Should you fail to produce your valid wedding certificate upon arrival, an extra cost will be charged directly at the hotel

// // // // // Payment Policy:
// // // // // Once you have made the reservation with us, you will recieve an email confirmation, and then once confirmed you will be sent a payment link for payment to secure your booking. 

// // // // // Kindly note that we currently accept the following payment methods.
// // // // // in EURO: VISA, MASTERCARD, AMERICAN EXPRESS
// // // // // in USD: VISA, MASTERCARD
// // // // // in MUR: VISA, MASTERCARD

// // // // // In order to secure online payments in EURO, the beachcomber hotels website uses the internationally recognized PAYBOX secure payment system, which incorporates the SSL (Secure Locket Layer) security protocol.

// // // // // The PAYBOX payment interface also makes use of 3-D-Secure technology. Designed by Visa and MasterCard, this system authenticates cardholders and prevents fraudulent use of their bank cards. The 3-D-Secure system automatically redirects you to a bank interface where a code or personal piece of information provided separately by the bank must be entered after the card number, expiry date and cryptogram have been provided. Once authenticated, the transaction is then either authorized or declined by the bank.

// // // // // In order to secure online payments in USD, AUD, GBP and MUR, the Beachcomber Hotels website uses MIPS secure payment system, which incorporates the SSL (Secure Locket Layer) security protocol.
// // // // // All confidential data (16-digit card number, expiry date and cryptogram) are transmitted directly in encrypted format to the payment gateway servers (of PayBox and MIPS) without ever passing through any physical device within the Beachcomber Hotels server system.

// // // // // Once the bank details are validated, the secure payment management systems send an authorization request to the bank card network. 
// // // // // The remote payment system then delivers an electronic certificate. In accordance with the provisions of articles 1316 et seq. of the French Civil Code, the electronic certificate shall serve as proof of the transaction date and amount and will be kept in the proper archives set up by Beachcomber Hotels. Dates and times contained on the server shall serve as official records for the parties.

// // // // // Kindly note that making an online payment implies that you are fully authorized to use the bank card provided for payment and that you have sufficient funds to cover the total cost of the transaction.

// // // // // For all transactions, payment of the booking shall constitute signature and express acceptance of the sale with all that is included in the price.

// // // // // Payment Conditions:
// // // // // Beachcomber Resorts & Hotels will not accept cash payments in excess of 500,000 rupees or an equivalent amount in foreign currency in order to comply with Section 5(1) of the Financial Intelligence and Anti-Money Laundering Act 2002.

// // // // // Payment and Cancellation Details:
// // // // // All prices listed on this website are in EUR/ USD/ AUD/ GBP/ MUR/ZAR, unless specified otherwise. Promotions displayed on this website can be changed or withdrawn without prior notice. Blackout dates may apply.
// // // // // Cancellations will not be processed unless we receive an official cancellation request by mail at bookings@beachcomber.com.
// // // // // After you have cancelled a booking, you will receive an email to confirm your cancellation at the e-mail address you provided at the time of booking.
// // // // // To prevent any fraud, refunds (when applicable) will only be made to the credit card used to secure the booking.

// // // // // Year round EXCEPT from 20th December 2024 to 8th January 2025 inclusive (all hotels excluding villas at Dinarobin Beachcomber and Paradis Beachcomber)
// // // // // Payment conditions:
// // // // // A deposit of 50%* of the total amount due is required to confirm and secure your booking
// // // // // The remaining balance should be settled directly at hotel upon arrival
// // // // // In case of “Non-Refundable” offer, 100% of the total amount due will be required to confirm and secure your booking

// // // // // Cancellations and amendments:
// // // // // No cancellation fee for stays cancelled up to 8 days prior to arrival
// // // // // Cancellations (full or partial) as from 7 days prior to arrival: 100% of the total amount will be charged
// // // // // No show: 100% of the amount deposit will be charged
// // // // // Unexpected departures: no refund for nights booked
// // // // // “Non-Refundable” offer: no refund for nights booked

// // // // // Cancellations and amendment for Royal Palm Beachcomber Luxury:
// // // // // For Junior Suites, Tropical Suites, Ocean Suites only 
// // // // // No cancellation fee for stays cancelled up to 8 days prior to arrival
// // // // // 7 days or less prior to arrival 100% cancellation fee on whole stay
// // // // // No-Show 100% cancellation fee on whole stay
// // // // // Unexpected departure 100% cancellation fee on the unused nights
// // // // // Name changes will be accepted 7 days prior to arrival

// // // // // For Palm Suites, Penthouse, Presidential Suites and Royal Villa only
// // // // // 21 days or less prior to arrival 100% cancellation fee on whole stay
// // // // // No-Show 100% cancellation fee on whole stay
// // // // // Unexpected departure 100% cancellation fee on the unused nights
// // // // // Name changes will be accepted 7 days prior to arrival

// // // // // Cancellation fees for 10 or more adults at Royal Palm Beachcomber Luxury:
// // // // // Partial cancellation fees apply as per conditions stated above.

// // // // // PRIME SEASON: from 20th December 2024 to 8th January 2025 inclusive.
// // // // // Payment conditions:
// // // // // A deposit of 50%* of the total amount due is required to confirm and secure your booking
// // // // // The remaining balance should be settled by 15th November
// // // // // *Conditions apply depending on the country of residence.

// // // // // Cancellation and no-show policy (20th December 2024 to 8th January 2025)
// // // // // All hotels excluding Paradis Beachcomber and Dinarobin Beachcomber Villas
// // // // // 45 to 31 days prior to arrival: 25% fee of the amount of the whole stay will be charged
// // // // // 30 days or less to arrival: 100% fee of the amount of the whole stay will be charged
// // // // // No show: 100% fee of the amount of the whole stay will be charged
// // // // // Unexpected departure: 100% fee on the unused nights

// // // // // Applicable procedures in case of flight delays/cancellations due to unforeseen circumstances (Including Cyclones):
// // // // // Details as stated above.

// // // // // Reservations:
// // // // // Kindly note that prices displayed on our website are subject to change in the course of the season.
// // // // // Please read and check carefully all the information contained in our confirmation email.
// // // // // You are required to present our confirmation email when checking-in at the hotel.

// // // // // Visa Requirements:
// // // // // We suggest you inform yourself about the entry/visa requirements before making your reservation.
// // // // //     `;

// // // // //     // Quote Wording for Display and Email
// // // // //     const quoteWording = `
// // // // // Welcome to the Mauritian Dream and thank you for choosing us as your travel partner. A consultant will be in contact with you soon to plan your dream holiday.

// // // // // If you have any questions you are welcome to contact us via email at info@themauritiusdream.com or WhatsApp on +230 5483 7078 - Contact: Alicia Venter.

// // // // // Please review the quote details below and our Terms and Conditions.
// // // // //     `;

// // // // //     const handleSelectPackage = (pkg) => {
// // // // //         setSelectedPackage(pkg);
// // // // //         setShowTandCModal(true);
// // // // //     };

// // // // //     const closeTandCModal = () => {
// // // // //         setShowTandCModal(false);
// // // // //         setSelectedPackage(null);
// // // // //     };

// // // // //     return (
// // // // //         <div className="booking-container">
// // // // //             {/* Section with three boxes linking to pages */}
// // // // //             <div className="info-boxes">
// // // // //                 <div className="info-box" onClick={() => window.location.href = '/about'}>
// // // // //                     <img src="/box1.png" alt="Box 1" className="box-image" />
// // // // //                     <h3>About Us</h3>
// // // // //                 </div>
// // // // //                 <div className="info-box" onClick={() => window.location.href = '/gallery'}>
// // // // //                     <img src="/box2.png" alt="Box 2" className="box-image" />
// // // // //                     <h3>Gallery</h3>
// // // // //                 </div>
// // // // //                 <div className="info-box" onClick={() => window.location.href = '/contact'}>
// // // // //                     <img src="/box3.png" alt="Box 3" className="box-image" />
// // // // //                     <h3>Contact Us</h3>
// // // // //                 </div>
// // // // //             </div>

// // // // //             {apiResponse ? (
// // // // //                 apiResponse.errorMsg ? (
// // // // //                     <div className="no-availability">
// // // // //                         <h3>Unfortunately, there is no availability at this time for your selected dates at the hotel.</h3>
// // // // //                         <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
// // // // //                             Get Another Quote
// // // // //                         </button>
// // // // //                     </div>
// // // // //                 ) : (
// // // // //                     <>
// // // // //                         <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
// // // // //                             Get Another Quote
// // // // //                         </button>

// // // // //                         {/* Display hotel overview and gallery */}
// // // // //                         <div className="hotel-overview">
// // // // //                             <h3>{apiResponse.hotelName}</h3>
// // // // //                             <p>{apiResponse.hotelDescription}</p>
// // // // //                             <div className="hotel-gallery">
// // // // //                                 {apiResponse.hotelImages?.map((image, imgIndex) => (
// // // // //                                     <img key={imgIndex} className="thumbnail" src={image.imageURL} alt={`Hotel image ${imgIndex + 1}`} />
// // // // //                                 ))}
// // // // //                             </div>
// // // // //                         </div>

// // // // //                         {/* Display room options */}
// // // // //                         {apiResponse.roomOptions && apiResponse.roomOptions.length > 0 && (
// // // // //                             apiResponse.roomOptions.map((room, index) => (
// // // // //                                 <div key={index} className="room-box">
// // // // //                                     <div className="room-description">
// // // // //                                         <h5>{room.accomProductName}</h5>
// // // // //                                         <p>{room.accomProdDescription}</p>
// // // // //                                         <h6>Packages Available:</h6>
// // // // //                                         {room.packages?.map((pkg, pkgIndex) => (
// // // // //                                             <div key={pkgIndex} className="package-box">
// // // // //                                                 <p><strong>Package:</strong> {pkg.packageDesc}</p>
// // // // //                                                 <p><strong>Total Price (ZAR):</strong> {formatCurrency(pkg.packagePriceZARFrom)}</p>
// // // // //                                                 <p><strong>Price per Person (ZAR):</strong> {formatCurrency(pkg.pricePerPersonZARFrom)}</p>
// // // // //                                                 {/* Added a button to select this package and open terms & conditions modal */}
// // // // //                                                 <button className="select-package-btn" onClick={() => handleSelectPackage(pkg)}>
// // // // //                                                     Select Package
// // // // //                                                 </button>
// // // // //                                             </div>
// // // // //                                         ))}
// // // // //                                     </div>
// // // // //                                     <div>
// // // // //                                         <img className="room-image" src={room.productImages[0].imageURL} alt={`Room image ${index + 1}`} />
// // // // //                                     </div>
// // // // //                                 </div>
// // // // //                             ))
// // // // //                         )}

// // // // //                         {/* Display flight information */}
// // // // //                         {apiResponse.airGDS && apiResponse.airGDS.length > 0 && (
// // // // //                             apiResponse.airGDS.map((flight, flightIndex) => (
// // // // //                                 <div key={flightIndex} className="flight-box">
// // // // //                                     <p><strong>From:</strong> {flight.fromArpt} ({flight.fromArptCode})</p>
// // // // //                                     <p><strong>To:</strong> {flight.toArpt} ({flight.toArptCode})</p>
// // // // //                                     <p><strong>Carrier:</strong> {flight.carrier}</p>
// // // // //                                     <p><strong>Departure:</strong> {new Date(flight.depDateTime).toLocaleString()}</p>
// // // // //                                     <p><strong>Arrival:</strong> {new Date(flight.arrDateTime).toLocaleString()}</p>
// // // // //                                 </div>
// // // // //                             ))
// // // // //                         )}

// // // // //                         {/* Display inclusions */}
// // // // //                         {apiResponse.inclusions && apiResponse.inclusions.length > 0 && (
// // // // //                             <div className="inclusions-section">
// // // // //                                 <h6>Inclusions:</h6>
// // // // //                                 <ul>
// // // // //                                     {apiResponse.inclusions.map((inclusion, idx) => (
// // // // //                                         <li key={idx}>{inclusion}</li>
// // // // //                                     ))}
// // // // //                                 </ul>
// // // // //                             </div>
// // // // //                         )}
// // // // //                     </>
// // // // //                 )
// // // // //             ) : (
// // // // //                 <form onSubmit={handleFormSubmit} className="booking-form">
// // // // //                     <div className="form-row">
// // // // //                         <div className="form-group full-width">
// // // // //                             <label>Choose your Hotel</label>
// // // // //                             <select name="hotelCode" value={formData.hotelCode} onChange={handleInputChange}>
// // // // //                                 <option value="MAU">Mauricia Beachcomber</option>
// // // // //                                 <option value="CAN">Cannonier Beachcomber</option>
// // // // //                                 <option value="VIC">Victoria Beachcomber</option>
// // // // //                                 <option value="PAR">Paradis Beachcomber</option>
// // // // //                                 <option value="SHA">Shandrani Beachcomber</option>
// // // // //                                 <option value="DIN">Dinarobin Beachcomber</option>
// // // // //                                 <option value="TAB">Troux Au Biches Beachcomber</option>
// // // // //                                 <option value="RP">Royal Palm Beachcomber</option>
// // // // //                             </select>
// // // // //                         </div>
// // // // //                     </div>
// // // // //                     <div className="form-row">
// // // // //                         <div className="form-group left-align">
// // // // //                             <label>Select Departure Date</label>
// // // // //                             <input type="date" name="dateDeparture" value={formData.dateDeparture} onChange={handleInputChange} required />
// // // // //                         </div>
// // // // //                         <div className="form-group right-align">
// // // // //                             <label>Select Return Date</label>
// // // // //                             <input type="date" name="dateReturn" value={formData.dateReturn} onChange={handleInputChange} required />
// // // // //                         </div>
// // // // //                     </div>
// // // // //                     <div className="form-row">
// // // // //                         <div className="form-group left-align">
// // // // //                             <label>Departure City</label>
// // // // //                             <select name="departureCity" value={formData.departureCity} onChange={handleInputChange}>
// // // // //                                 <option value="JOHANNESBURG">Johannesburg</option>
// // // // //                                 <option value="CAPE TOWN">Cape Town</option>
// // // // //                                 <option value="DURBAN">Durban</option>
// // // // //                             </select>
// // // // //                         </div>
// // // // //                         <div className="form-group right-align">
// // // // //                             <label>Select Flight Class</label>
// // // // //                             <select name="flightCabin" value={formData.flightCabin} onChange={handleInputChange}>
// // // // //                                 <option value="Cheapest">Cheapest</option>
// // // // //                                 <option value="Business">Business</option>
// // // // //                                 <option value="Economy">Economy</option>
// // // // //                             </select>
// // // // //                         </div>
// // // // //                     </div>
// // // // //                     <div className="form-row">
// // // // //                         <div className="form-group left-align">
// // // // //                             <label>Number of Adults</label>
// // // // //                             <input type="number" name="nbrAdults" value={formData.nbrAdults} onChange={handleInputChange} min="1" max="6" />
// // // // //                         </div>
// // // // //                         <div className="form-group center-align">
// // // // //                             <label>Number of Children</label>
// // // // //                             <input type="number" name="nbrChildren" value={formData.nbrChildren} onChange={handleInputChange} min="0" max="4" />
// // // // //                         </div>
// // // // //                         <div className="form-group right-align">
// // // // //                             <label>Number of Infants</label>
// // // // //                             <input type="number" name="nbrInfants" value={formData.nbrInfants} onChange={handleInputChange} min="0" max="2" />
// // // // //                         </div>
// // // // //                     </div>
// // // // //                     <button type="submit" disabled={loading}>
// // // // //                         {loading ? 'Loading...' : 'Get Quote'}
// // // // //                     </button>
// // // // //                 </form>
// // // // //             )}

// // // // //             {loading && <p>Loading room options...</p>}

// // // // //             {isGalleryOpen && (
// // // // //                 <div className="gallery-popup">
// // // // //                     <div className="gallery-overlay" onClick={closeGallery}></div>
// // // // //                     <div className="gallery-content">
// // // // //                         {selectedImages.map((image, imgIndex) => (
// // // // //                             <img key={imgIndex} src={image.imageURL} alt={`Gallery image ${imgIndex + 1}`} />
// // // // //                         ))}
// // // // //                         <button className="close-button" onClick={closeGallery}>Close</button>
// // // // //                     </div>
// // // // //                 </div>
// // // // //             )}

// // // // //             {/* Modal for Terms & Conditions and selected package display */}
// // // // //             {showTandCModal && selectedPackage && (
// // // // //                 <>
// // // // //                     <div className="tandc-overlay" onClick={closeTandCModal}></div>
// // // // //                     <div className="tandc-modal">
// // // // //                     <div className="tandc-content">
// // // // //                         <h3>Selected Package Details</h3>
// // // // //                         <p><strong>Package:</strong> {selectedPackage.packageDesc}</p>
// // // // //                         <p><strong>Total Price (ZAR):</strong> {formatCurrency(selectedPackage.packagePriceZARFrom)}</p>
// // // // //                         <p><strong>Price per Person (ZAR):</strong> {formatCurrency(selectedPackage.pricePerPersonZARFrom)}</p>

// // // // //                         <div className="quote-wording">
// // // // //                         <p>{quoteWording}</p>
// // // // //                         </div>

// // // // //                         <h4>Terms & Conditions</h4>
// // // // //                         <div className="terms-scrollable">
// // // // //                         <div className="terms-scrollable">
// // // // //                             <div>{termsAndConditions}</div>
// // // // //                         </div>
// // // // //                         </div>

// // // // //                         <button className="confirm-button" onClick={closeTandCModal}>Confirm & Proceed</button>
// // // // //                     </div>
// // // // //                     </div>
// // // // //                 </>
// // // // //                 )}


// // // // //             <footer className="footer">
// // // // //                 {/* © 2024 Mauritius Dream. All rights reserved. */}
// // // // //             </footer>
// // // // //         </div>
// // // // //     );
// // // // // };

// // // // // export default BookingForm;

// // // // import React, { useState } from 'react';
// // // // import './BookingForm.css';

// // // // const BookingForm = () => {
// // // //     const [formData, setFormData] = useState({
// // // //         hotelCode: 'MAU',
// // // //         dateDeparture: '',
// // // //         dateReturn: '',
// // // //         departureCity: 'JOHANNESBURG',
// // // //         includeAir: true,
// // // //         flightAdvanced: 'All Airlines',
// // // //         flightCabin: 'Cheapest',
// // // //         honeymoonRates: false,
// // // //         repeaterRates: false,
// // // //         weddingRates: false,
// // // //         nbrAdults: '2',
// // // //         nbrChildren: '0',
// // // //         nbrInfants: '0'
// // // //     });

// // // //     const [apiResponse, setApiResponse] = useState(null);
// // // //     const [selectedImages, setSelectedImages] = useState([]);
// // // //     const [isGalleryOpen, setGalleryOpen] = useState(false);
// // // //     const [loading, setLoading] = useState(false); 
// // // //     const [showTandCModal, setShowTandCModal] = useState(false);
// // // //     const [selectedPackage, setSelectedPackage] = useState(null);

// // // //     // New states for steps after T&C acceptance
// // // //     const [acceptedTerms, setAcceptedTerms] = useState(false);
// // // //     const [showDetailsForm, setShowDetailsForm] = useState(false);

// // // //     // Details form fields
// // // //     const [detailsFormData, setDetailsFormData] = useState({
// // // //         fullName: '',
// // // //         email: '',
// // // //         mobileNumber: '',
// // // //         billingName: '',
// // // //         billingAddress: ''
// // // //     });

// // // //     // Controls enabling the Accept & Confirm button
// // // //     const [termsChecked, setTermsChecked] = useState(false);

// // // //     const handleInputChange = (e) => {
// // // //         const { name, value, type, checked } = e.target;
// // // //         setFormData((prevData) => ({
// // // //             ...prevData,
// // // //             [name]: type === 'checkbox' ? checked : value
// // // //         }));
// // // //     };

// // // //     const handleFormSubmit = async (e) => {
// // // //         e.preventDefault();
// // // //         setLoading(true);
// // // //         setApiResponse(null);

// // // //         const payload = {
// // // //             ...formData,
// // // //             dateDeparture: new Date(formData.dateDeparture).toISOString(),
// // // //             dateReturn: new Date(formData.dateReturn).toISOString(),
// // // //         };
        
// // // //         try {
// // // //             const response = await fetch('/api/getQuote', {
// // // //                 method: 'POST',
// // // //                 headers: {
// // // //                     'Content-Type': 'application/json',
// // // //                     'BeachcomberKey': 'YOUR_API_KEY_HERE'
// // // //                 },
// // // //                 body: JSON.stringify(payload)
// // // //             });

// // // //             if (!response.ok) {
// // // //                 throw new Error('Failed to fetch room options');
// // // //             }

// // // //             const data = await response.json();
// // // //             setApiResponse(data);
// // // //         } catch (error) {
// // // //             console.error('Error during API request:', error);
// // // //         } finally {
// // // //             setLoading(false);
// // // //         }
// // // //     };

// // // //     const handleImageClick = (images) => {
// // // //         setSelectedImages(images);
// // // //         setGalleryOpen(true);
// // // //     };

// // // //     const closeGallery = () => {
// // // //         setGalleryOpen(false);
// // // //     };

// // // //     const handleGetAnotherQuote = () => {
// // // //         setApiResponse(null);
// // // //         setFormData({
// // // //             hotelCode: 'MAU',
// // // //             dateDeparture: '',
// // // //             dateReturn: '',
// // // //             departureCity: 'JOHANNESBURG',
// // // //             includeAir: true,
// // // //             flightAdvanced: 'All Airlines',
// // // //             flightCabin: 'Cheapest',
// // // //             honeymoonRates: false,
// // // //             repeaterRates: false,
// // // //             weddingRates: false,
// // // //             nbrAdults: '2',
// // // //             nbrChildren: '0',
// // // //             nbrInfants: '0'
// // // //         });
// // // //     };

// // // //     const formatCurrency = (amount) => {
// // // //         return `R${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;
// // // //     };

// // // //     // Full Terms and Conditions text
// // // //     const termsAndConditions = `
// // // // For foreigners:

// // // // While we strive to always provide the latest information and details about our product offerings on this website, please be sure to check with the hotel on any specifics that may be important to you. Information presented here is subject to change. Contents of this website only apply to Beachcomber Resorts & Hotels in Mauritius.

// // // // Room Disclaimer:
// // // // Beachcomber carries out regular improvements to its existing accommodation and therefore room facilities or room layout of the reserved room may be different from the displayed photo.

// // // // Check in Check out:
// // // // Check-in: 2 p.m.
// // // // Check-out: noon
// // // // For an early check-in or late check-out after 6.00 p.m., one full night will be charged.
// // // // Rate charged will be based on meal plan booked and offers.
// // // // For a late check-out until 6.00 p.m. (on request), 50% of the applicable rate will be charged – except for Royal Palm Beachcomber Luxury, where one full night will be charged for a pre-bookings and late check-outs.

// // // // For nights falling between 20 December 2024 and 8 January 2025
// // // // One full night will be charged for pre-bookings and late check-outs
// // // // For Villas at Dinarobin Beachcomber, Paradis Beachcomber and Trou aux Biches Beachcomber, early check-in and late check-out are granted without any supplement

// // // // Honeymoon Policy:
// // // // Valid 12 months as from the wedding date
// // // // Your wedding certificate will be requested at the time of check-in
// // // // Should you fail to produce your valid wedding certificate upon arrival, an extra cost will be charged directly at the hotel

// // // // Wedding Anniversary Policy:
// // // // The anniversary date should be within 6 months prior to the date of arrival or 6 months after the date of arrival
// // // // Offer applicable to every 5th wedding anniversary
// // // // Your wedding certificate will be requested at the time of check-in
// // // // Should you fail to produce your valid wedding certificate upon arrival, an extra cost will be charged directly at the hotel

// // // // Payment Policy:
// // // // Once you have made the reservation with us, you will recieve an email confirmation, and then once confirmed you will be sent a payment link for payment to secure your booking. 

// // // // Kindly note that we currently accept the following payment methods.
// // // // in EURO: VISA, MASTERCARD, AMERICAN EXPRESS
// // // // in USD: VISA, MASTERCARD
// // // // in MUR: VISA, MASTERCARD

// // // // In order to secure online payments in EURO, the beachcomber hotels website uses the internationally recognized PAYBOX secure payment system, which incorporates the SSL (Secure Locket Layer) security protocol.

// // // // The PAYBOX payment interface also makes use of 3-D-Secure technology. Designed by Visa and MasterCard, this system authenticates cardholders and prevents fraudulent use of their bank cards. The 3-D-Secure system automatically redirects you to a bank interface where a code or personal piece of information provided separately by the bank must be entered after the card number, expiry date and cryptogram have been provided. Once authenticated, the transaction is then either authorized or declined by the bank.

// // // // In order to secure online payments in USD, AUD, GBP and MUR, the Beachcomber Hotels website uses MIPS secure payment system, which incorporates the SSL (Secure Locket Layer) security protocol.
// // // // All confidential data (16-digit card number, expiry date and cryptogram) are transmitted directly in encrypted format to the payment gateway servers (of PayBox and MIPS) without ever passing through any physical device within the Beachcomber Hotels server system.

// // // // Once the bank details are validated, the secure payment management systems send an authorization request to the bank card network. 
// // // // The remote payment system then delivers an electronic certificate. In accordance with the provisions of articles 1316 et seq. of the French Civil Code, the electronic certificate shall serve as proof of the transaction date and amount and will be kept in the proper archives set up by Beachcomber Hotels. Dates and times contained on the server shall serve as official records for the parties.

// // // // Kindly note that making an online payment implies that you are fully authorized to use the bank card provided for payment and that you have sufficient funds to cover the total cost of the transaction.

// // // // For all transactions, payment of the booking shall constitute signature and express acceptance of the sale with all that is included in the price.

// // // // Payment Conditions:
// // // // Beachcomber Resorts & Hotels will not accept cash payments in excess of 500,000 rupees or an equivalent amount in foreign currency in order to comply with Section 5(1) of the Financial Intelligence and Anti-Money Laundering Act 2002.

// // // // Payment and Cancellation Details:
// // // // All prices listed on this website are in EUR/ USD/ AUD/ GBP/ MUR/ZAR, unless specified otherwise. Promotions displayed on this website can be changed or withdrawn without prior notice. Blackout dates may apply.
// // // // Cancellations will not be processed unless we receive an official cancellation request by mail at bookings@beachcomber.com.
// // // // After you have cancelled a booking, you will receive an email to confirm your cancellation at the e-mail address you provided at the time of booking.
// // // // To prevent any fraud, refunds (when applicable) will only be made to the credit card used to secure the booking.

// // // // Year round EXCEPT from 20th December 2024 to 8th January 2025 inclusive (all hotels excluding villas at Dinarobin Beachcomber and Paradis Beachcomber)
// // // // Payment conditions:
// // // // A deposit of 50%* of the total amount due is required to confirm and secure your booking
// // // // The remaining balance should be settled directly at hotel upon arrival
// // // // In case of “Non-Refundable” offer, 100% of the total amount due will be required to confirm and secure your booking

// // // // Cancellations and amendments:
// // // // No cancellation fee for stays cancelled up to 8 days prior to arrival
// // // // Cancellations (full or partial) as from 7 days prior to arrival: 100% of the total amount will be charged
// // // // No show: 100% of the amount deposit will be charged
// // // // Unexpected departures: no refund for nights booked
// // // // “Non-Refundable” offer: no refund for nights booked

// // // // Cancellations and amendment for Royal Palm Beachcomber Luxury:
// // // // For Junior Suites, Tropical Suites, Ocean Suites only 
// // // // No cancellation fee for stays cancelled up to 8 days prior to arrival
// // // // 7 days or less prior to arrival 100% cancellation fee on whole stay
// // // // No-Show 100% cancellation fee on whole stay
// // // // Unexpected departure 100% cancellation fee on the unused nights
// // // // Name changes will be accepted 7 days prior to arrival

// // // // For Palm Suites, Penthouse, Presidential Suites and Royal Villa only
// // // // 21 days or less prior to arrival 100% cancellation fee on whole stay
// // // // No-Show 100% cancellation fee on whole stay
// // // // Unexpected departure 100% cancellation fee on the unused nights
// // // // Name changes will be accepted 7 days prior to arrival

// // // // Cancellation fees for 10 or more adults at Royal Palm Beachcomber Luxury:
// // // // Partial cancellation fees apply as per conditions stated above.

// // // // PRIME SEASON: from 20th December 2024 to 8th January 2025 inclusive.
// // // // Payment conditions:
// // // // A deposit of 50%* of the total amount due is required to confirm and secure your booking
// // // // The remaining balance should be settled by 15th November
// // // // *Conditions apply depending on the country of residence.

// // // // Cancellation and no-show policy (20th December 2024 to 8th January 2025)
// // // // All hotels excluding Paradis Beachcomber and Dinarobin Beachcomber Villas
// // // // 45 to 31 days prior to arrival: 25% fee of the amount of the whole stay will be charged
// // // // 30 days or less to arrival: 100% fee of the amount of the whole stay will be charged
// // // // No show: 100% fee of the amount of the whole stay will be charged
// // // // Unexpected departure: 100% fee on the unused nights

// // // // Applicable procedures in case of flight delays/cancellations due to unforeseen circumstances (Including Cyclones):
// // // // Details as stated above.

// // // // Reservations:
// // // // Kindly note that prices displayed on our website are subject to change in the course of the season.
// // // // Please read and check carefully all the information contained in our confirmation email.
// // // // You are required to present our confirmation email when checking-in at the hotel.

// // // // Visa Requirements:
// // // // We suggest you inform yourself about the entry/visa requirements before making your reservation.
// // // //     `;

// // // //     const quoteWording = `
// // // // Welcome to the Mauritian Dream and thank you for choosing us as your travel partner. A consultant will be in contact with you soon to plan your dream holiday.

// // // // If you have any questions you are welcome to contact us via email at info@themauritiusdream.com or WhatsApp on +230 5483 7078 - Contact: Alicia Venter.

// // // // Please review the quote details below and our Terms and Conditions.
// // // //     `;

// // // //     const handleSelectPackage = (pkg) => {
// // // //         setSelectedPackage(pkg);
// // // //         setShowTandCModal(true);
// // // //     };

// // // //     const closeTandCModal = () => {
// // // //         setShowTandCModal(false);
// // // //         setSelectedPackage(null);
// // // //         setTermsChecked(false);
// // // //     };

// // // //     const handleAcceptTerms = () => {
// // // //         if (!termsChecked) return;
// // // //         setAcceptedTerms(true);
// // // //         setShowTandCModal(false);
// // // //         setShowDetailsForm(true);
// // // //     };

// // // //     const handleDetailsFormChange = (e) => {
// // // //         const { name, value } = e.target;
// // // //         setDetailsFormData((prev) => ({ ...prev, [name]: value }));
// // // //     };

// // // //     const handleDetailsSubmit = async (e) => {
// // // //         e.preventDefault();

// // // //         const { fullName, email, mobileNumber, billingAddress } = detailsFormData;
// // // //         if (!fullName || !email || !mobileNumber || !billingAddress) {
// // // //             alert('Please fill in all required fields.');
// // // //             return;
// // // //         }

// // // //         const emailData = {
// // // //             fullName: detailsFormData.fullName,
// // // //             email: detailsFormData.email,
// // // //             mobileNumber: detailsFormData.mobileNumber,
// // // //             billingName: detailsFormData.billingName,
// // // //             billingAddress: detailsFormData.billingAddress,
// // // //             selectedPackage,
// // // //             termsAndConditions,
// // // //             quoteWording
// // // //         };

// // // //         try {
// // // //             const response = await fetch('/api/sendBookingEmail', {
// // // //                 method: 'POST',
// // // //                 headers: {
// // // //                     'Content-Type': 'application/json'
// // // //                 },
// // // //                 body: JSON.stringify(emailData)
// // // //             });

// // // //             if (!response.ok) {
// // // //                 throw new Error('Failed to send booking email');
// // // //             }

// // // //             alert('Your booking details have been sent successfully!');
// // // //             setShowDetailsForm(false);
// // // //             setSelectedPackage(null);
// // // //             setAcceptedTerms(false);
// // // //             setApiResponse(null);
// // // //         } catch (error) {
// // // //             console.error('Error sending booking email:', error);
// // // //             alert('There was an error sending your details. Please try again later.');
// // // //         }
// // // //     };

// // // //     return (
// // // //         <div className="booking-container">
// // // //             <div className="info-boxes">
// // // //                 <div className="info-box" onClick={() => window.location.href = '/about'}>
// // // //                     <img src="/box1.png" alt="Box 1" className="box-image" />
// // // //                     <h3>About Us</h3>
// // // //                 </div>
// // // //                 <div className="info-box" onClick={() => window.location.href = '/gallery'}>
// // // //                     <img src="/box2.png" alt="Box 2" className="box-image" />
// // // //                     <h3>Gallery</h3>
// // // //                 </div>
// // // //                 <div className="info-box" onClick={() => window.location.href = '/contact'}>
// // // //                     <img src="/box3.png" alt="Box 3" className="box-image" />
// // // //                     <h3>Contact Us</h3>
// // // //                 </div>
// // // //             </div>

// // // //             {/* If not accepted terms yet and not showing details form */}
// // // //             {!acceptedTerms && !showDetailsForm && (
// // // //                 <>
// // // //                 {apiResponse ? (
// // // //                     apiResponse.errorMsg ? (
// // // //                         <div className="no-availability">
// // // //                             <h3>Unfortunately, there is no availability at this time for your selected dates at the hotel.</h3>
// // // //                             <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
// // // //                                 Get Another Quote
// // // //                             </button>
// // // //                         </div>
// // // //                     ) : (
// // // //                         <>
// // // //                             <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
// // // //                                 Get Another Quote
// // // //                             </button>

// // // //                             <div className="hotel-overview">
// // // //                                 <h3>{apiResponse.hotelName}</h3>
// // // //                                 <p>{apiResponse.hotelDescription}</p>
// // // //                                 <div className="hotel-gallery">
// // // //                                     {apiResponse.hotelImages?.map((image, imgIndex) => (
// // // //                                         <img key={imgIndex} className="thumbnail" src={image.imageURL} alt={`Hotel image ${imgIndex + 1}`} />
// // // //                                     ))}
// // // //                                 </div>
// // // //                             </div>

// // // //                             {apiResponse.roomOptions && apiResponse.roomOptions.length > 0 && (
// // // //                                 apiResponse.roomOptions.map((room, index) => (
// // // //                                     <div key={index} className="room-box">
// // // //                                         <div className="room-description">
// // // //                                             <h5>{room.accomProductName}</h5>
// // // //                                             <p>{room.accomProdDescription}</p>
// // // //                                             <h6>Packages Available:</h6>
// // // //                                             {room.packages?.map((pkg, pkgIndex) => (
// // // //                                                 <div key={pkgIndex} className="package-box">
// // // //                                                     <p><strong>Package:</strong> {pkg.packageDesc}</p>
// // // //                                                     <p><strong>Total Price (ZAR):</strong> {formatCurrency(pkg.packagePriceZARFrom)}</p>
// // // //                                                     <p><strong>Price per Person (ZAR):</strong> {formatCurrency(pkg.pricePerPersonZARFrom)}</p>
// // // //                                                     <button className="select-package-btn" onClick={() => handleSelectPackage(pkg)}>
// // // //                                                         Select Package
// // // //                                                     </button>
// // // //                                                 </div>
// // // //                                             ))}
// // // //                                         </div>
// // // //                                         <div>
// // // //                                             <img className="room-image" src={room.productImages[0].imageURL} alt={`Room image ${index + 1}`} />
// // // //                                         </div>
// // // //                                     </div>
// // // //                                 ))
// // // //                             )}

// // // //                             {apiResponse.airGDS && apiResponse.airGDS.length > 0 && (
// // // //                                 apiResponse.airGDS.map((flight, flightIndex) => (
// // // //                                     <div key={flightIndex} className="flight-box">
// // // //                                         <p><strong>From:</strong> {flight.fromArpt} ({flight.fromArptCode})</p>
// // // //                                         <p><strong>To:</strong> {flight.toArpt} ({flight.toArptCode})</p>
// // // //                                         <p><strong>Carrier:</strong> {flight.carrier}</p>
// // // //                                         <p><strong>Departure:</strong> {new Date(flight.depDateTime).toLocaleString()}</p>
// // // //                                         <p><strong>Arrival:</strong> {new Date(flight.arrDateTime).toLocaleString()}</p>
// // // //                                     </div>
// // // //                                 ))
// // // //                             )}

// // // //                             {apiResponse.inclusions && apiResponse.inclusions.length > 0 && (
// // // //                                 <div className="inclusions-section">
// // // //                                     <h6>Inclusions:</h6>
// // // //                                     <ul>
// // // //                                         {apiResponse.inclusions.map((inclusion, idx) => (
// // // //                                             <li key={idx}>{inclusion}</li>
// // // //                                         ))}
// // // //                                     </ul>
// // // //                                 </div>
// // // //                             )}
// // // //                         </>
// // // //                     )
// // // //                 ) : (
// // // //                     <form onSubmit={handleFormSubmit} className="booking-form">
// // // //                         <div className="form-row">
// // // //                             <div className="form-group full-width">
// // // //                                 <label>Choose your Hotel</label>
// // // //                                 <select name="hotelCode" value={formData.hotelCode} onChange={handleInputChange}>
// // // //                                     <option value="MAU">Mauricia Beachcomber</option>
// // // //                                     <option value="CAN">Cannonier Beachcomber</option>
// // // //                                     <option value="VIC">Victoria Beachcomber</option>
// // // //                                     <option value="PAR">Paradis Beachcomber</option>
// // // //                                     <option value="SHA">Shandrani Beachcomber</option>
// // // //                                     <option value="DIN">Dinarobin Beachcomber</option>
// // // //                                     <option value="TAB">Troux Au Biches Beachcomber</option>
// // // //                                     <option value="RP">Royal Palm Beachcomber</option>
// // // //                                 </select>
// // // //                             </div>
// // // //                         </div>
// // // //                         <div className="form-row">
// // // //                             <div className="form-group left-align">
// // // //                                 <label>Select Departure Date</label>
// // // //                                 <input type="date" name="dateDeparture" value={formData.dateDeparture} onChange={handleInputChange} required />
// // // //                             </div>
// // // //                             <div className="form-group right-align">
// // // //                                 <label>Select Return Date</label>
// // // //                                 <input type="date" name="dateReturn" value={formData.dateReturn} onChange={handleInputChange} required />
// // // //                             </div>
// // // //                         </div>
// // // //                         <div className="form-row">
// // // //                             <div className="form-group left-align">
// // // //                                 <label>Departure City</label>
// // // //                                 <select name="departureCity" value={formData.departureCity} onChange={handleInputChange}>
// // // //                                     <option value="JOHANNESBURG">Johannesburg</option>
// // // //                                     <option value="CAPE TOWN">Cape Town</option>
// // // //                                     <option value="DURBAN">Durban</option>
// // // //                                 </select>
// // // //                             </div>
// // // //                             <div className="form-group right-align">
// // // //                                 <label>Select Flight Class</label>
// // // //                                 <select name="flightCabin" value={formData.flightCabin} onChange={handleInputChange}>
// // // //                                     <option value="Cheapest">Cheapest</option>
// // // //                                     <option value="Business">Business</option>
// // // //                                     <option value="Economy">Economy</option>
// // // //                                 </select>
// // // //                             </div>
// // // //                         </div>
// // // //                         <div className="form-row">
// // // //                             <div className="form-group left-align">
// // // //                                 <label>Number of Adults</label>
// // // //                                 <input type="number" name="nbrAdults" value={formData.nbrAdults} onChange={handleInputChange} min="1" max="6" />
// // // //                             </div>
// // // //                             <div className="form-group center-align">
// // // //                                 <label>Number of Children</label>
// // // //                                 <input type="number" name="nbrChildren" value={formData.nbrChildren} onChange={handleInputChange} min="0" max="4" />
// // // //                             </div>
// // // //                             <div className="form-group right-align">
// // // //                                 <label>Number of Infants</label>
// // // //                                 <input type="number" name="nbrInfants" value={formData.nbrInfants} onChange={handleInputChange} min="0" max="2" />
// // // //                             </div>
// // // //                         </div>
// // // //                         <button type="submit" disabled={loading}>
// // // //                             {loading ? 'Loading...' : 'Get Quote'}
// // // //                         </button>
// // // //                     </form>
// // // //                 )}
// // // //                 {loading && <p>Loading room options...</p>}
// // // //                 </>
// // // //             )}

// // // //             {isGalleryOpen && (
// // // //                 <div className="gallery-popup">
// // // //                     <div className="gallery-overlay" onClick={closeGallery}></div>
// // // //                     <div className="gallery-content">
// // // //                         {selectedImages.map((image, imgIndex) => (
// // // //                             <img key={imgIndex} src={image.imageURL} alt={`Gallery image ${imgIndex + 1}`} />
// // // //                         ))}
// // // //                         <button className="close-button" onClick={closeGallery}>Close</button>
// // // //                     </div>
// // // //                 </div>
// // // //             )}

// // // //             {/* T&C Modal */}
// // // //             {showTandCModal && selectedPackage && (
// // // //                 <>
// // // //                     <div className="tandc-overlay" onClick={closeTandCModal}></div>
// // // //                     <div className="tandc-modal">
// // // //                         <div className="tandc-content">
// // // //                             <h3>Selected Package Details</h3>
// // // //                             <p><strong>Package:</strong> {selectedPackage.packageDesc}</p>
// // // //                             <p><strong>Total Price (ZAR):</strong> {formatCurrency(selectedPackage.packagePriceZARFrom)}</p>
// // // //                             <p><strong>Price per Person (ZAR):</strong> {formatCurrency(selectedPackage.pricePerPersonZARFrom)}</p>

// // // //                             <div className="quote-wording">
// // // //                                 <p>{quoteWording}</p>
// // // //                             </div>

// // // //                             <h4>Terms & Conditions</h4>
// // // //                             <div className="terms-scrollable" style={{whiteSpace:'pre-wrap', wordBreak:'break-word'}}>
// // // //                                 {termsAndConditions}
// // // //                             </div>

// // // //                             <div style={{marginTop:'20px'}}>
// // // //                                 <label>
// // // //                                     <input 
// // // //                                         type="checkbox" 
// // // //                                         checked={termsChecked} 
// // // //                                         onChange={(e)=>setTermsChecked(e.target.checked)} 
// // // //                                     /> 
// // // //                                     I hereby accept the terms and conditions, and understand them.
// // // //                                 </label>
// // // //                             </div>

// // // //                             <button 
// // // //                                 className="confirm-button" 
// // // //                                 onClick={handleAcceptTerms}
// // // //                                 disabled={!termsChecked}
// // // //                                 style={{marginTop:'20px'}}
// // // //                             >
// // // //                                 Accept & Confirm
// // // //                             </button>
// // // //                         </div>
// // // //                     </div>
// // // //                 </>
// // // //             )}

// // // //             {/* Details Form Page */}
// // // //             {showDetailsForm && (
// // // //                 <div className="details-form-container">
// // // //                     <h3>Please Provide Your Details</h3>
// // // //                     <form onSubmit={handleDetailsSubmit} className="details-form">
// // // //                         <div className="form-group">
// // // //                             <label>Full Name (As per Passport) *</label>
// // // //                             <input 
// // // //                                 type="text" 
// // // //                                 name="fullName" 
// // // //                                 value={detailsFormData.fullName} 
// // // //                                 onChange={handleDetailsFormChange} 
// // // //                                 required
// // // //                             />
// // // //                         </div>
// // // //                         <div className="form-group">
// // // //                             <label>Email Address *</label>
// // // //                             <input 
// // // //                                 type="email" 
// // // //                                 name="email" 
// // // //                                 value={detailsFormData.email} 
// // // //                                 onChange={handleDetailsFormChange}
// // // //                                 required 
// // // //                             />
// // // //                         </div>
// // // //                         <div className="form-group">
// // // //                             <label>Mobile Number (with International Dialing Code) *</label>
// // // //                             <input 
// // // //                                 type="text" 
// // // //                                 name="mobileNumber" 
// // // //                                 value={detailsFormData.mobileNumber} 
// // // //                                 onChange={handleDetailsFormChange}
// // // //                                 required 
// // // //                             />
// // // //                         </div>
// // // //                         <div className="form-group">
// // // //                             <label>Billing Name (Optional)</label>
// // // //                             <input 
// // // //                                 type="text" 
// // // //                                 name="billingName" 
// // // //                                 value={detailsFormData.billingName} 
// // // //                                 onChange={handleDetailsFormChange} 
// // // //                             />
// // // //                         </div>
// // // //                         <div className="form-group">
// // // //                             <label>Billing Address *</label>
// // // //                             <input 
// // // //                                 type="text" 
// // // //                                 name="billingAddress" 
// // // //                                 value={detailsFormData.billingAddress} 
// // // //                                 onChange={handleDetailsFormChange}
// // // //                                 required 
// // // //                             />
// // // //                         </div>
// // // //                         <button type="submit" className="confirm-button">Confirm</button>
// // // //                     </form>
// // // //                 </div>
// // // //             )}

// // // //             <footer className="footer">
// // // //                 {/* © 2024 Mauritius Dream. All rights reserved. */}
// // // //             </footer>
// // // //         </div>
// // // //     );
// // // // };

// // // // export default BookingForm;
// // // import React, { useState } from 'react';
// // // import './BookingForm.css';

// // // const BookingForm = () => {
// // //     const [formData, setFormData] = useState({
// // //         hotelCode: 'MAU',
// // //         dateDeparture: '',
// // //         dateReturn: '',
// // //         departureCity: 'JOHANNESBURG',
// // //         includeAir: true,
// // //         flightAdvanced: 'All Airlines',
// // //         flightCabin: 'Cheapest',
// // //         honeymoonRates: false,
// // //         repeaterRates: false,
// // //         weddingRates: false,
// // //         nbrAdults: '2',
// // //         nbrChildren: '0',
// // //         nbrInfants: '0'
// // //     });

// // //     const [apiResponse, setApiResponse] = useState(null);
// // //     const [selectedImages, setSelectedImages] = useState([]);
// // //     const [isGalleryOpen, setGalleryOpen] = useState(false);
// // //     const [loading, setLoading] = useState(false); 
// // //     const [showTandCModal, setShowTandCModal] = useState(false);
// // //     const [selectedPackage, setSelectedPackage] = useState(null);

// // //     // Steps after T&C acceptance
// // //     const [acceptedTerms, setAcceptedTerms] = useState(false);
// // //     const [showDetailsForm, setShowDetailsForm] = useState(false);

// // //     // Details form fields
// // //     const [detailsFormData, setDetailsFormData] = useState({
// // //         fullName: '',
// // //         email: '',
// // //         mobileNumber: '',
// // //         billingName: '',
// // //         billingAddress: ''
// // //     });

// // //     // Checkbox state for T&C modal
// // //     const [termsChecked, setTermsChecked] = useState(false);

// // //     const handleInputChange = (e) => {
// // //         const { name, value, type, checked } = e.target;
// // //         setFormData((prevData) => ({
// // //             ...prevData,
// // //             [name]: type === 'checkbox' ? checked : value
// // //         }));
// // //     };

// // //     const handleFormSubmit = async (e) => {
// // //         e.preventDefault();
// // //         setLoading(true);
// // //         setApiResponse(null);

// // //         const payload = {
// // //             ...formData,
// // //             dateDeparture: new Date(formData.dateDeparture).toISOString(),
// // //             dateReturn: new Date(formData.dateReturn).toISOString(),
// // //         };
        
// // //         try {
// // //             const response = await fetch('/api/getQuote', {
// // //                 method: 'POST',
// // //                 headers: {
// // //                     'Content-Type': 'application/json',
// // //                     'BeachcomberKey': 'YOUR_API_KEY_HERE'
// // //                 },
// // //                 body: JSON.stringify(payload)
// // //             });

// // //             if (!response.ok) {
// // //                 throw new Error('Failed to fetch room options');
// // //             }

// // //             const data = await response.json();
// // //             setApiResponse(data);
// // //         } catch (error) {
// // //             console.error('Error during API request:', error);
// // //         } finally {
// // //             setLoading(false);
// // //         }
// // //     };

// // //     const handleImageClick = (images) => {
// // //         setSelectedImages(images);
// // //         setGalleryOpen(true);
// // //     };

// // //     const closeGallery = () => {
// // //         setGalleryOpen(false);
// // //     };

// // //     const handleGetAnotherQuote = () => {
// // //         setApiResponse(null);
// // //         setFormData({
// // //             hotelCode: 'MAU',
// // //             dateDeparture: '',
// // //             dateReturn: '',
// // //             departureCity: 'JOHANNESBURG',
// // //             includeAir: true,
// // //             flightAdvanced: 'All Airlines',
// // //             flightCabin: 'Cheapest',
// // //             honeymoonRates: false,
// // //             repeaterRates: false,
// // //             weddingRates: false,
// // //             nbrAdults: '2',
// // //             nbrChildren: '0',
// // //             nbrInfants: '0'
// // //         });
// // //     };

// // //     const formatCurrency = (amount) => {
// // //         return `R${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;
// // //     };

// // //     // Full Terms and Conditions text
// // //     const termsAndConditions = `
// // // For foreigners:

// // // While we strive to always provide the latest information and details about our product offerings on this website, please be sure to check with the hotel on any specifics that may be important to you. Information presented here is subject to change. Contents of this website only apply to Beachcomber Resorts & Hotels in Mauritius.

// // // Room Disclaimer:
// // // Beachcomber carries out regular improvements to its existing accommodation and therefore room facilities or room layout of the reserved room may be different from the displayed photo.

// // // Check in Check out:
// // // Check-in: 2 p.m.
// // // Check-out: noon
// // // For an early check-in or late check-out after 6.00 p.m., one full night will be charged.
// // // Rate charged will be based on meal plan booked and offers.
// // // For a late check-out until 6.00 p.m. (on request), 50% of the applicable rate will be charged – except for Royal Palm Beachcomber Luxury, where one full night will be charged for a pre-bookings and late check-outs.

// // // For nights falling between 20 December 2024 and 8 January 2025
// // // One full night will be charged for pre-bookings and late check-outs
// // // For Villas at Dinarobin Beachcomber, Paradis Beachcomber and Trou aux Biches Beachcomber, early check-in and late check-out are granted without any supplement

// // // Honeymoon Policy:
// // // Valid 12 months as from the wedding date
// // // Your wedding certificate will be requested at the time of check-in
// // // Should you fail to produce your valid wedding certificate upon arrival, an extra cost will be charged directly at the hotel

// // // Wedding Anniversary Policy:
// // // The anniversary date should be within 6 months prior to the date of arrival or 6 months after the date of arrival
// // // Offer applicable to every 5th wedding anniversary
// // // Your wedding certificate will be requested at the time of check-in
// // // Should you fail to produce your valid wedding certificate upon arrival, an extra cost will be charged directly at the hotel

// // // Payment Policy:
// // // Once you have made the reservation with us, you will recieve an email confirmation, and then once confirmed you will be sent a payment link for payment to secure your booking. 

// // // Kindly note that we currently accept the following payment methods.
// // // in EURO: VISA, MASTERCARD, AMERICAN EXPRESS
// // // in USD: VISA, MASTERCARD
// // // in MUR: VISA, MASTERCARD

// // // In order to secure online payments in EURO, the beachcomber hotels website uses the internationally recognized PAYBOX secure payment system, which incorporates the SSL (Secure Locket Layer) security protocol.

// // // The PAYBOX payment interface also makes use of 3-D-Secure technology. Designed by Visa and MasterCard, this system authenticates cardholders and prevents fraudulent use of their bank cards. The 3-D-Secure system automatically redirects you to a bank interface where a code or personal piece of information provided separately by the bank must be entered after the card number, expiry date and cryptogram have been provided. Once authenticated, the transaction is then either authorized or declined by the bank.

// // // In order to secure online payments in USD, AUD, GBP and MUR, the Beachcomber Hotels website uses MIPS secure payment system, which incorporates the SSL (Secure Locket Layer) security protocol.
// // // All confidential data (16-digit card number, expiry date and cryptogram) are transmitted directly in encrypted format to the payment gateway servers (of PayBox and MIPS) without ever passing through any physical device within the Beachcomber Hotels server system.

// // // Once the bank details are validated, the secure payment management systems send an authorization request to the bank card network. 
// // // The remote payment system then delivers an electronic certificate. In accordance with the provisions of articles 1316 et seq. of the French Civil Code, the electronic certificate shall serve as proof of the transaction date and amount and will be kept in the proper archives set up by Beachcomber Hotels. Dates and times contained on the server shall serve as official records for the parties.

// // // Kindly note that making an online payment implies that you are fully authorized to use the bank card provided for payment and that you have sufficient funds to cover the total cost of the transaction.

// // // For all transactions, payment of the booking shall constitute signature and express acceptance of the sale with all that is included in the price.

// // // Payment Conditions:
// // // Beachcomber Resorts & Hotels will not accept cash payments in excess of 500,000 rupees or an equivalent amount in foreign currency in order to comply with Section 5(1) of the Financial Intelligence and Anti-Money Laundering Act 2002.

// // // Payment and Cancellation Details:
// // // All prices listed on this website are in EUR/ USD/ AUD/ GBP/ MUR/ZAR, unless specified otherwise. Promotions displayed on this website can be changed or withdrawn without prior notice. Blackout dates may apply.
// // // Cancellations will not be processed unless we receive an official cancellation request by mail at bookings@beachcomber.com.
// // // After you have cancelled a booking, you will receive an email to confirm your cancellation at the e-mail address you provided at the time of booking.
// // // To prevent any fraud, refunds (when applicable) will only be made to the credit card used to secure the booking.

// // // Year round EXCEPT from 20th December 2024 to 8th January 2025 inclusive (all hotels excluding villas at Dinarobin Beachcomber and Paradis Beachcomber)
// // // Payment conditions:
// // // A deposit of 50%* of the total amount due is required to confirm and secure your booking
// // // The remaining balance should be settled directly at hotel upon arrival
// // // In case of “Non-Refundable” offer, 100% of the total amount due will be required to confirm and secure your booking

// // // Cancellations and amendments:
// // // No cancellation fee for stays cancelled up to 8 days prior to arrival
// // // Cancellations (full or partial) as from 7 days prior to arrival: 100% of the total amount will be charged
// // // No show: 100% of the amount deposit will be charged
// // // Unexpected departures: no refund for nights booked
// // // “Non-Refundable” offer: no refund for nights booked

// // // Cancellations and amendment for Royal Palm Beachcomber Luxury:
// // // For Junior Suites, Tropical Suites, Ocean Suites only 
// // // No cancellation fee for stays cancelled up to 8 days prior to arrival
// // // 7 days or less prior to arrival 100% cancellation fee on whole stay
// // // No-Show 100% cancellation fee on whole stay
// // // Unexpected departure 100% cancellation fee on the unused nights
// // // Name changes will be accepted 7 days prior to arrival

// // // For Palm Suites, Penthouse, Presidential Suites and Royal Villa only
// // // 21 days or less prior to arrival 100% cancellation fee on whole stay
// // // No-Show 100% cancellation fee on whole stay
// // // Unexpected departure 100% cancellation fee on the unused nights
// // // Name changes will be accepted 7 days prior to arrival

// // // Cancellation fees for 10 or more adults at Royal Palm Beachcomber Luxury:
// // // Partial cancellation fees apply as per conditions stated above.

// // // PRIME SEASON: from 20th December 2024 to 8th January 2025 inclusive.
// // // Payment conditions:
// // // A deposit of 50%* of the total amount due is required to confirm and secure your booking
// // // The remaining balance should be settled by 15th November
// // // *Conditions apply depending on the country of residence.

// // // Cancellation and no-show policy (20th December 2024 to 8th January 2025)
// // // All hotels excluding Paradis Beachcomber and Dinarobin Beachcomber Villas
// // // 45 to 31 days prior to arrival: 25% fee of the amount of the whole stay will be charged
// // // 30 days or less to arrival: 100% fee of the amount of the whole stay will be charged
// // // No show: 100% fee of the amount of the whole stay will be charged
// // // Unexpected departure: 100% fee on the unused nights

// // // Applicable procedures in case of flight delays/cancellations due to unforeseen circumstances (Including Cyclones):
// // // Details as stated above.

// // // Reservations:
// // // Kindly note that prices displayed on our website are subject to change in the course of the season.
// // // Please read and check carefully all the information contained in our confirmation email.
// // // You are required to present our confirmation email when checking-in at the hotel.

// // // Visa Requirements:
// // // We suggest you inform yourself about the entry/visa requirements before making your reservation.
// // //     `;

// // //     const quoteWording = `
// // // Welcome to the Mauritian Dream and thank you for choosing us as your travel partner. A consultant will be in contact with you soon to plan your dream holiday.

// // // If you have any questions you are welcome to contact us via email at info@themauritiusdream.com or WhatsApp on +230 5483 7078 - Contact: Alicia Venter.

// // // Please review the quote details below and our Terms and Conditions.
// // //     `;

// // //     const handleSelectPackage = (pkg) => {
// // //         setSelectedPackage(pkg);
// // //         setShowTandCModal(true);
// // //     };

// // //     const closeTandCModal = () => {
// // //         setShowTandCModal(false);
// // //         setSelectedPackage(null);
// // //         setTermsChecked(false);
// // //     };

// // //     const handleAcceptTerms = () => {
// // //         if (!termsChecked) return;
// // //         setAcceptedTerms(true);
// // //         setShowTandCModal(false);
// // //         setShowDetailsForm(true);
// // //     };

// // //     const handleDetailsFormChange = (e) => {
// // //         const { name, value } = e.target;
// // //         setDetailsFormData((prev) => ({ ...prev, [name]: value }));
// // //     };

// // //     const handleDetailsSubmit = async (e) => {
// // //         e.preventDefault();

// // //         const { fullName, email, mobileNumber, billingAddress } = detailsFormData;
// // //         if (!fullName || !email || !mobileNumber || !billingAddress) {
// // //             alert('Please fill in all required fields.');
// // //             return;
// // //         }

// // //         const emailData = {
// // //             fullName: detailsFormData.fullName,
// // //             email: detailsFormData.email,
// // //             mobileNumber: detailsFormData.mobileNumber,
// // //             billingName: detailsFormData.billingName,
// // //             billingAddress: detailsFormData.billingAddress,
// // //             selectedPackage,
// // //             termsAndConditions,
// // //             quoteWording
// // //         };

// // //         try {
// // //             const response = await fetch('/api/sendBookingEmail', {
// // //                 method: 'POST',
// // //                 headers: {
// // //                     'Content-Type': 'application/json'
// // //                 },
// // //                 body: JSON.stringify(emailData)
// // //             });

// // //             if (!response.ok) {
// // //                 throw new Error('Failed to send booking email');
// // //             }

// // //             alert('Your booking details have been sent successfully!');
// // //             setShowDetailsForm(false);
// // //             setSelectedPackage(null);
// // //             setAcceptedTerms(false);
// // //             setApiResponse(null);
// // //         } catch (error) {
// // //             console.error('Error sending booking email:', error);
// // //             alert('There was an error sending your details. Please try again later.');
// // //         }
// // //     };

// // //     return (
// // //         <div className="booking-container">
// // //             <div className="info-boxes">
// // //                 <div className="info-box" onClick={() => window.location.href = '/about'}>
// // //                     <img src="/box1.png" alt="Box 1" className="box-image" />
// // //                     <h3>About Us</h3>
// // //                 </div>
// // //                 <div className="info-box" onClick={() => window.location.href = '/gallery'}>
// // //                     <img src="/box2.png" alt="Box 2" className="box-image" />
// // //                     <h3>Gallery</h3>
// // //                 </div>
// // //                 <div className="info-box" onClick={() => window.location.href = '/contact'}>
// // //                     <img src="/box3.png" alt="Box 3" className="box-image" />
// // //                     <h3>Contact Us</h3>
// // //                 </div>
// // //             </div>

// // //             {!acceptedTerms && !showDetailsForm && (
// // //                 <>
// // //                 {apiResponse ? (
// // //                     apiResponse.errorMsg ? (
// // //                         <div className="no-availability">
// // //                             <h3>Unfortunately, there is no availability at this time for your selected dates at the hotel.</h3>
// // //                             <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
// // //                                 Get Another Quote
// // //                             </button>
// // //                         </div>
// // //                     ) : (
// // //                         <>
// // //                             <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
// // //                                 Get Another Quote
// // //                             </button>

// // //                             <div className="hotel-overview">
// // //                                 <h3>{apiResponse.hotelName}</h3>
// // //                                 <p>{apiResponse.hotelDescription}</p>
// // //                                 <div className="hotel-gallery">
// // //                                     {apiResponse.hotelImages?.map((image, imgIndex) => (
// // //                                         <img key={imgIndex} className="thumbnail" src={image.imageURL} alt={`Hotel image ${imgIndex + 1}`} />
// // //                                     ))}
// // //                                 </div>
// // //                             </div>

// // //                             {apiResponse.roomOptions && apiResponse.roomOptions.length > 0 && (
// // //                                 apiResponse.roomOptions.map((room, index) => (
// // //                                     <div key={index} className="room-box">
// // //                                         <div className="room-description">
// // //                                             <h5>{room.accomProductName}</h5>
// // //                                             <p>{room.accomProdDescription}</p>
// // //                                             <h6>Packages Available:</h6>
// // //                                             {room.packages?.map((pkg, pkgIndex) => (
// // //                                                 <div key={pkgIndex} className="package-box">
// // //                                                     <p><strong>Package:</strong> {pkg.packageDesc}</p>
// // //                                                     <p><strong>Total Price (ZAR):</strong> {formatCurrency(pkg.packagePriceZARFrom)}</p>
// // //                                                     <p><strong>Price per Person (ZAR):</strong> {formatCurrency(pkg.pricePerPersonZARFrom)}</p>
// // //                                                     <button className="select-package-btn" onClick={() => handleSelectPackage(pkg)}>
// // //                                                         Select Package
// // //                                                     </button>
// // //                                                 </div>
// // //                                             ))}
// // //                                         </div>
// // //                                         <div>
// // //                                             <img className="room-image" src={room.productImages[0].imageURL} alt={`Room image ${index + 1}`} />
// // //                                         </div>
// // //                                     </div>
// // //                                 ))
// // //                             )}

// // //                             {apiResponse.airGDS && apiResponse.airGDS.length > 0 && (
// // //                                 apiResponse.airGDS.map((flight, flightIndex) => (
// // //                                     <div key={flightIndex} className="flight-box">
// // //                                         <p><strong>From:</strong> {flight.fromArpt} ({flight.fromArptCode})</p>
// // //                                         <p><strong>To:</strong> {flight.toArpt} ({flight.toArptCode})</p>
// // //                                         <p><strong>Carrier:</strong> {flight.carrier}</p>
// // //                                         <p><strong>Departure:</strong> {new Date(flight.depDateTime).toLocaleString()}</p>
// // //                                         <p><strong>Arrival:</strong> {new Date(flight.arrDateTime).toLocaleString()}</p>
// // //                                     </div>
// // //                                 ))
// // //                             )}

// // //                             {apiResponse.inclusions && apiResponse.inclusions.length > 0 && (
// // //                                 <div className="inclusions-section">
// // //                                     <h6>Inclusions:</h6>
// // //                                     <ul>
// // //                                         {apiResponse.inclusions.map((inclusion, idx) => (
// // //                                             <li key={idx}>{inclusion}</li>
// // //                                         ))}
// // //                                     </ul>
// // //                                 </div>
// // //                             )}
// // //                         </>
// // //                     )
// // //                 ) : (
// // //                     <form onSubmit={handleFormSubmit} className="booking-form">
// // //                         <div className="form-row">
// // //                             <div className="form-group full-width">
// // //                                 <label>Choose your Hotel</label>
// // //                                 <select name="hotelCode" value={formData.hotelCode} onChange={handleInputChange}>
// // //                                     <option value="MAU">Mauricia Beachcomber</option>
// // //                                     <option value="CAN">Cannonier Beachcomber</option>
// // //                                     <option value="VIC">Victoria Beachcomber</option>
// // //                                     <option value="PAR">Paradis Beachcomber</option>
// // //                                     <option value="SHA">Shandrani Beachcomber</option>
// // //                                     <option value="DIN">Dinarobin Beachcomber</option>
// // //                                     <option value="TAB">Troux Au Biches Beachcomber</option>
// // //                                     <option value="RP">Royal Palm Beachcomber</option>
// // //                                 </select>
// // //                             </div>
// // //                         </div>
// // //                         <div className="form-row">
// // //                             <div className="form-group left-align">
// // //                                 <label>Select Departure Date</label>
// // //                                 <input type="date" name="dateDeparture" value={formData.dateDeparture} onChange={handleInputChange} required />
// // //                             </div>
// // //                             <div className="form-group right-align">
// // //                                 <label>Select Return Date</label>
// // //                                 <input type="date" name="dateReturn" value={formData.dateReturn} onChange={handleInputChange} required />
// // //                             </div>
// // //                         </div>
// // //                         <div className="form-row">
// // //                             <div className="form-group left-align">
// // //                                 <label>Departure City</label>
// // //                                 <select name="departureCity" value={formData.departureCity} onChange={handleInputChange}>
// // //                                     <option value="JOHANNESBURG">Johannesburg</option>
// // //                                     <option value="CAPE TOWN">Cape Town</option>
// // //                                     <option value="DURBAN">Durban</option>
// // //                                 </select>
// // //                             </div>
// // //                             <div className="form-group right-align">
// // //                                 <label>Select Flight Class</label>
// // //                                 <select name="flightCabin" value={formData.flightCabin} onChange={handleInputChange}>
// // //                                     <option value="Cheapest">Cheapest</option>
// // //                                     <option value="Business">Business</option>
// // //                                     <option value="Economy">Economy</option>
// // //                                 </select>
// // //                             </div>
// // //                         </div>
// // //                         <div className="form-row">
// // //                             <div className="form-group left-align">
// // //                                 <label>Number of Adults</label>
// // //                                 <input type="number" name="nbrAdults" value={formData.nbrAdults} onChange={handleInputChange} min="1" max="6" />
// // //                             </div>
// // //                             <div className="form-group center-align">
// // //                                 <label>Number of Children</label>
// // //                                 <input type="number" name="nbrChildren" value={formData.nbrChildren} onChange={handleInputChange} min="0" max="4" />
// // //                             </div>
// // //                             <div className="form-group right-align">
// // //                                 <label>Number of Infants</label>
// // //                                 <input type="number" name="nbrInfants" value={formData.nbrInfants} onChange={handleInputChange} min="0" max="2" />
// // //                             </div>
// // //                         </div>
// // //                         <button type="submit" disabled={loading}>
// // //                             {loading ? 'Loading...' : 'Get Quote'}
// // //                         </button>
// // //                     </form>
// // //                 )}
// // //                 {loading && <p>Loading room options...</p>}
// // //                 </>
// // //             )}

// // //             {isGalleryOpen && (
// // //                 <div className="gallery-popup">
// // //                     <div className="gallery-overlay" onClick={closeGallery}></div>
// // //                     <div className="gallery-content">
// // //                         {selectedImages.map((image, imgIndex) => (
// // //                             <img key={imgIndex} src={image.imageURL} alt={`Gallery image ${imgIndex + 1}`} />
// // //                         ))}
// // //                         <button className="close-button" onClick={closeGallery}>Close</button>
// // //                     </div>
// // //                 </div>
// // //             )}

// // //             {/* T&C Modal */}
// // //             {showTandCModal && selectedPackage && (
// // //                 <>
// // //                     <div className="tandc-overlay" onClick={closeTandCModal}></div>
// // //                     <div className="tandc-modal">
// // //                         <div className="tandc-content">
// // //                             <h3>Selected Package Details</h3>
// // //                             <p><strong>Package:</strong> {selectedPackage.packageDesc}</p>
// // //                             <p><strong>Total Price (ZAR):</strong> {formatCurrency(selectedPackage.packagePriceZARFrom)}</p>
// // //                             <p><strong>Price per Person (ZAR):</strong> {formatCurrency(selectedPackage.pricePerPersonZARFrom)}</p>

// // //                             <div className="quote-wording">
// // //                                 <p>{quoteWording}</p>
// // //                             </div>

// // //                             <h4>Terms & Conditions</h4>
// // //                             <div className="terms-scrollable" style={{whiteSpace:'pre-wrap', wordBreak:'break-word'}}>
// // //                                 {termsAndConditions}
// // //                             </div>

// // //                             <div style={{marginTop:'20px', display:'flex', alignItems:'center'}}>
// // //                                 <input 
// // //                                     type="checkbox" 
// // //                                     checked={termsChecked} 
// // //                                     onChange={(e)=>setTermsChecked(e.target.checked)} 
// // //                                     style={{marginRight:'8px'}}
// // //                                 />
// // //                                 <span>I hereby accept the terms and conditions, and understand them.</span>
// // //                             </div>

// // //                             <button 
// // //                                 className="confirm-button" 
// // //                                 onClick={handleAcceptTerms}
// // //                                 disabled={!termsChecked}
// // //                                 style={{marginTop:'20px'}}
// // //                             >
// // //                                 Accept & Confirm
// // //                             </button>
// // //                         </div>
// // //                     </div>
// // //                 </>
// // //             )}

// // //             {/* Details Form Page */}
// // //             {showDetailsForm && (
// // //                 <div className="details-form-container" style={{width:'70%', margin:'0 auto'}}>
// // //                     <h3>Please Provide Your Details</h3>
// // //                     <form onSubmit={handleDetailsSubmit} className="details-form">
// // //                         <div className="form-group">
// // //                             <label>Full Name (As per Passport) *</label>
// // //                             <input 
// // //                                 type="text" 
// // //                                 name="fullName" 
// // //                                 value={detailsFormData.fullName} 
// // //                                 onChange={handleDetailsFormChange} 
// // //                                 required
// // //                             />
// // //                         </div>
// // //                         <div className="form-group">
// // //                             <label>Email Address *</label>
// // //                             <input 
// // //                                 type="email" 
// // //                                 name="email" 
// // //                                 value={detailsFormData.email} 
// // //                                 onChange={handleDetailsFormChange}
// // //                                 required 
// // //                             />
// // //                         </div>
// // //                         <div className="form-group">
// // //                             <label>Mobile Number (with International Dialing Code) *</label>
// // //                             <input 
// // //                                 type="text" 
// // //                                 name="mobileNumber" 
// // //                                 value={detailsFormData.mobileNumber} 
// // //                                 onChange={handleDetailsFormChange}
// // //                                 required 
// // //                             />
// // //                         </div>
// // //                         <div className="form-group">
// // //                             <label>Billing Name (Optional)</label>
// // //                             <input 
// // //                                 type="text" 
// // //                                 name="billingName" 
// // //                                 value={detailsFormData.billingName} 
// // //                                 onChange={handleDetailsFormChange} 
// // //                             />
// // //                         </div>
// // //                         <div className="form-group">
// // //                             <label>Billing Address *</label>
// // //                             <input 
// // //                                 type="text" 
// // //                                 name="billingAddress" 
// // //                                 value={detailsFormData.billingAddress} 
// // //                                 onChange={handleDetailsFormChange}
// // //                                 required 
// // //                             />
// // //                         </div>
// // //                         <button type="submit" className="confirm-button">Confirm</button>
// // //                     </form>
// // //                 </div>
// // //             )}

// // //             <footer className="footer">
// // //                 {/* © 2024 Mauritius Dream. All rights reserved. */}
// // //             </footer>
// // //         </div>
// // //     );
// // // };

// // // export default BookingForm;
// // import React, { useState } from 'react';
// // import './BookingForm.css';

// // const BookingForm = () => {
// //     const [formData, setFormData] = useState({
// //         hotelCode: 'MAU',
// //         dateDeparture: '',
// //         dateReturn: '',
// //         departureCity: 'JOHANNESBURG',
// //         includeAir: true,
// //         flightAdvanced: 'All Airlines',
// //         flightCabin: 'Cheapest',
// //         honeymoonRates: false,
// //         repeaterRates: false,
// //         weddingRates: false,
// //         nbrAdults: '2',
// //         nbrChildren: '0',
// //         nbrInfants: '0'
// //     });

// //     const [apiResponse, setApiResponse] = useState(null);
// //     const [selectedImages, setSelectedImages] = useState([]);
// //     const [isGalleryOpen, setGalleryOpen] = useState(false);
// //     const [loading, setLoading] = useState(false); 
// //     const [showTandCModal, setShowTandCModal] = useState(false);
// //     const [selectedPackage, setSelectedPackage] = useState(null);

// //     const [acceptedTerms, setAcceptedTerms] = useState(false);
// //     const [showDetailsForm, setShowDetailsForm] = useState(false);

// //     const [detailsFormData, setDetailsFormData] = useState({
// //         fullName: '',
// //         email: '',
// //         mobileNumber: '',
// //         billingName: '',
// //         billingAddress: ''
// //     });

// //     const [termsChecked, setTermsChecked] = useState(false);

// //     const handleInputChange = (e) => {
// //         const { name, value, type, checked } = e.target;
// //         setFormData((prevData) => ({
// //             ...prevData,
// //             [name]: type === 'checkbox' ? checked : value
// //         }));
// //     };

// //     const handleFormSubmit = async (e) => {
// //         e.preventDefault();
// //         setLoading(true);
// //         setApiResponse(null);

// //         const payload = {
// //             ...formData,
// //             dateDeparture: new Date(formData.dateDeparture).toISOString(),
// //             dateReturn: new Date(formData.dateReturn).toISOString(),
// //         };
        
// //         try {
// //             const response = await fetch('/api/getQuote', {
// //                 method: 'POST',
// //                 headers: {
// //                     'Content-Type': 'application/json',
// //                     'BeachcomberKey': 'YOUR_API_KEY_HERE'
// //                 },
// //                 body: JSON.stringify(payload)
// //             });

// //             if (!response.ok) {
// //                 throw new Error('Failed to fetch room options');
// //             }

// //             const data = await response.json();
// //             setApiResponse(data);
// //         } catch (error) {
// //             console.error('Error during API request:', error);
// //         } finally {
// //             setLoading(false);
// //         }
// //     };

// //     const handleImageClick = (images) => {
// //         setSelectedImages(images);
// //         setGalleryOpen(true);
// //     };

// //     const closeGallery = () => {
// //         setGalleryOpen(false);
// //     };

// //     const handleGetAnotherQuote = () => {
// //         setApiResponse(null);
// //         setFormData({
// //             hotelCode: 'MAU',
// //             dateDeparture: '',
// //             dateReturn: '',
// //             departureCity: 'JOHANNESBURG',
// //             includeAir: true,
// //             flightAdvanced: 'All Airlines',
// //             flightCabin: 'Cheapest',
// //             honeymoonRates: false,
// //             repeaterRates: false,
// //             weddingRates: false,
// //             nbrAdults: '2',
// //             nbrChildren: '0',
// //             nbrInfants: '0'
// //         });
// //     };

// //     const formatCurrency = (amount) => {
// //         return `R${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;
// //     };

// //     const termsAndConditions = `
// // For foreigners:

// // While we strive to always provide the latest information and details about our product offerings on this website, please be sure to check with the hotel on any specifics that may be important to you. Information presented here is subject to change. Contents of this website only apply to Beachcomber Resorts & Hotels in Mauritius.

// // Room Disclaimer:
// // Beachcomber carries out regular improvements to its existing accommodation and therefore room facilities or room layout of the reserved room may be different from the displayed photo.

// // Check in Check out:
// // Check-in: 2 p.m.
// // Check-out: noon
// // For an early check-in or late check-out after 6.00 p.m., one full night will be charged.
// // Rate charged will be based on meal plan booked and offers.
// // For a late check-out until 6.00 p.m. (on request), 50% of the applicable rate will be charged – except for Royal Palm Beachcomber Luxury, where one full night will be charged for pre-bookings and late check-outs.

// // For nights falling between 20 December 2024 and 8 January 2025
// // One full night will be charged for pre-bookings and late check-outs
// // For Villas at Dinarobin Beachcomber, Paradis Beachcomber and Trou aux Biches Beachcomber, early check-in and late check-out are granted without any supplement

// // Honeymoon Policy:
// // Valid 12 months as from the wedding date
// // Your wedding certificate will be requested at the time of check-in
// // Should you fail to produce your valid wedding certificate upon arrival, an extra cost will be charged directly at the hotel

// // Wedding Anniversary Policy:
// // The anniversary date should be within 6 months prior to the date of arrival or 6 months after the date of arrival
// // Offer applicable to every 5th wedding anniversary
// // Your wedding certificate will be requested at the time of check-in
// // Should you fail to produce your valid wedding certificate upon arrival, an extra cost will be charged directly at the hotel

// // Payment Policy:
// // Once you have made the reservation with us, you will receive an email confirmation, and then once confirmed you will be sent a payment link for payment to secure your booking.

// // Kindly note that we currently accept the following payment methods:
// // in EURO: VISA, MASTERCARD, AMERICAN EXPRESS
// // in USD: VISA, MASTERCARD
// // in MUR: VISA, MASTERCARD

// // In order to secure online payments in EURO, the beachcomber hotels website uses the internationally recognized PAYBOX secure payment system, which incorporates the SSL (Secure Locket Layer) security protocol.

// // The PAYBOX payment interface also makes use of 3-D-Secure technology. Designed by Visa and MasterCard, this system authenticates cardholders and prevents fraudulent use of their bank cards.

// // In order to secure online payments in USD, AUD, GBP and MUR, the Beachcomber Hotels website uses MIPS secure payment system, which incorporates the SSL (Secure Locket Layer) security protocol.
// // All confidential data are transmitted encrypted without passing through any physical device within the Beachcomber Hotels server system.

// // Once bank details are validated, an authorization request is sent to the bank card network and an electronic certificate is delivered. Making an online payment implies authorization and sufficient funds.

// // For all transactions, payment of the booking shall constitute signature and acceptance of the sale.

// // Payment Conditions:
// // Beachcomber Resorts & Hotels will not accept cash payments in excess of the legal limits.

// // Cancellation Policy:
// // Subject to hotel policies. Non-Refundable offers and specific seasons may have special rules.

// // Visa Requirements:
// // Kindly ensure you check visa requirements before travel.
// //     `;

// //     const quoteWording = `
// // Welcome to the Mauritian Dream and thank you for choosing us as your travel partner. A consultant will be in contact with you soon to plan your dream holiday.

// // If you have any questions you are welcome to contact us via email at info@themauritiusdream.com or WhatsApp on +230 5483 7078 - Contact: Alicia Venter.

// // Please review the quote details below and our Terms and Conditions.
// //     `;

// //     const handleSelectPackage = (pkg) => {
// //         setSelectedPackage(pkg);
// //         setShowTandCModal(true);
// //     };

// //     const closeTandCModal = () => {
// //         setShowTandCModal(false);
// //         setSelectedPackage(null);
// //         setTermsChecked(false);
// //     };

// //     const handleAcceptTerms = () => {
// //         if (!termsChecked) return;
// //         setAcceptedTerms(true);
// //         setShowTandCModal(false);
// //         setShowDetailsForm(true);
// //     };

// //     const handleDetailsFormChange = (e) => {
// //         const { name, value } = e.target;
// //         setDetailsFormData((prev) => ({ ...prev, [name]: value }));
// //     };

// //     const handleDetailsSubmit = async (e) => {
// //         e.preventDefault();

// //         const { fullName, email, mobileNumber, billingAddress } = detailsFormData;
// //         if (!fullName || !email || !mobileNumber || !billingAddress) {
// //             alert('Please fill in all required fields.');
// //             return;
// //         }

// //         // Include hotel name, dates, flight type, and number of persons
// //         const emailData = {
// //             fullName: detailsFormData.fullName,
// //             email: detailsFormData.email,
// //             mobileNumber: detailsFormData.mobileNumber,
// //             billingName: detailsFormData.billingName,
// //             billingAddress: detailsFormData.billingAddress,
// //             selectedPackage,
// //             termsAndConditions,
// //             quoteWording,
// //             hotelName: apiResponse?.hotelName || '',
// //             dateDeparture: formData.dateDeparture,
// //             dateReturn: formData.dateReturn,
// //             flightCabin: formData.flightCabin,
// //             nbrAdults: formData.nbrAdults,
// //             nbrChildren: formData.nbrChildren,
// //             nbrInfants: formData.nbrInfants
// //         };

// //         try {
// //             const response = await fetch('/api/sendBookingEmail', {
// //                 method: 'POST',
// //                 headers: {
// //                     'Content-Type': 'application/json'
// //                 },
// //                 body: JSON.stringify(emailData)
// //             });

// //             if (!response.ok) {
// //                 throw new Error('Failed to send booking email');
// //             }

// //             alert('Your booking details have been sent successfully!');
// //             setShowDetailsForm(false);
// //             setSelectedPackage(null);
// //             setAcceptedTerms(false);
// //             setApiResponse(null);
// //         } catch (error) {
// //             console.error('Error sending booking email:', error);
// //             alert('There was an error sending your details. Please try again later.');
// //         }
// //     };

// //     return (
// //         <div className="booking-container">
// //             <div className="info-boxes">
// //                 <div className="info-box" onClick={() => window.location.href = '/about'}>
// //                     <img src="/box1.png" alt="Box 1" className="box-image" />
// //                     <h3>About Us</h3>
// //                 </div>
// //                 <div className="info-box" onClick={() => window.location.href = '/gallery'}>
// //                     <img src="/box2.png" alt="Box 2" className="box-image" />
// //                     <h3>Gallery</h3>
// //                 </div>
// //                 <div className="info-box" onClick={() => window.location.href = '/contact'}>
// //                     <img src="/box3.png" alt="Box 3" className="box-image" />
// //                     <h3>Contact Us</h3>
// //                 </div>
// //             </div>

// //             {!acceptedTerms && !showDetailsForm && (
// //                 <>
// //                 {apiResponse ? (
// //                     apiResponse.errorMsg ? (
// //                         <div className="no-availability">
// //                             <h3>Unfortunately, there is no availability at this time for your selected dates at the hotel.</h3>
// //                             <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
// //                                 Get Another Quote
// //                             </button>
// //                         </div>
// //                     ) : (
// //                         <>
// //                             <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
// //                                 Get Another Quote
// //                             </button>

// //                             <div className="hotel-overview">
// //                                 <h3>{apiResponse.hotelName}</h3>
// //                                 <p>{apiResponse.hotelDescription}</p>
// //                                 <div className="hotel-gallery">
// //                                     {apiResponse.hotelImages?.map((image, imgIndex) => (
// //                                         <img key={imgIndex} className="thumbnail" src={image.imageURL} alt={`Hotel image ${imgIndex + 1}`} />
// //                                     ))}
// //                                 </div>
// //                             </div>

// //                             {apiResponse.roomOptions && apiResponse.roomOptions.length > 0 && (
// //                                 apiResponse.roomOptions.map((room, index) => (
// //                                     <div key={index} className="room-box">
// //                                         <div className="room-description">
// //                                             <h5>{room.accomProductName}</h5>
// //                                             <p>{room.accomProdDescription}</p>
// //                                             <h6>Packages Available:</h6>
// //                                             {room.packages?.map((pkg, pkgIndex) => (
// //                                                 <div key={pkgIndex} className="package-box">
// //                                                     <p><strong>Package:</strong> {pkg.packageDesc}</p>
// //                                                     <p><strong>Total Price (ZAR):</strong> {formatCurrency(pkg.packagePriceZARFrom)}</p>
// //                                                     <p><strong>Price per Person (ZAR):</strong> {formatCurrency(pkg.pricePerPersonZARFrom)}</p>
// //                                                     <button className="select-package-btn" onClick={() => handleSelectPackage(pkg)}>
// //                                                         Select Package
// //                                                     </button>
// //                                                 </div>
// //                                             ))}
// //                                         </div>
// //                                         <div>
// //                                             <img className="room-image" src={room.productImages[0].imageURL} alt={`Room image ${index + 1}`} />
// //                                         </div>
// //                                     </div>
// //                                 ))
// //                             )}

// //                             {apiResponse.airGDS && apiResponse.airGDS.length > 0 && (
// //                                 apiResponse.airGDS.map((flight, flightIndex) => (
// //                                     <div key={flightIndex} className="flight-box">
// //                                         <p><strong>From:</strong> {flight.fromArpt} ({flight.fromArptCode})</p>
// //                                         <p><strong>To:</strong> {flight.toArpt} ({flight.toArptCode})</p>
// //                                         <p><strong>Carrier:</strong> {flight.carrier}</p>
// //                                         <p><strong>Departure:</strong> {new Date(flight.depDateTime).toLocaleString()}</p>
// //                                         <p><strong>Arrival:</strong> {new Date(flight.arrDateTime).toLocaleString()}</p>
// //                                     </div>
// //                                 ))
// //                             )}

// //                             {apiResponse.inclusions && apiResponse.inclusions.length > 0 && (
// //                                 <div className="inclusions-section">
// //                                     <h6>Inclusions:</h6>
// //                                     <ul>
// //                                         {apiResponse.inclusions.map((inclusion, idx) => (
// //                                             <li key={idx}>{inclusion}</li>
// //                                         ))}
// //                                     </ul>
// //                                 </div>
// //                             )}
// //                         </>
// //                     )
// //                 ) : (
// //                     <form onSubmit={handleFormSubmit} className="booking-form">
// //                         <div className="form-row">
// //                             <div className="form-group full-width">
// //                                 <label>Choose your Hotel</label>
// //                                 <select name="hotelCode" value={formData.hotelCode} onChange={handleInputChange}>
// //                                     <option value="MAU">Mauricia Beachcomber</option>
// //                                     <option value="CAN">Cannonier Beachcomber</option>
// //                                     <option value="VIC">Victoria Beachcomber</option>
// //                                     <option value="PAR">Paradis Beachcomber</option>
// //                                     <option value="SHA">Shandrani Beachcomber</option>
// //                                     <option value="DIN">Dinarobin Beachcomber</option>
// //                                     <option value="TAB">Troux Au Biches Beachcomber</option>
// //                                     <option value="RP">Royal Palm Beachcomber</option>
// //                                 </select>
// //                             </div>
// //                         </div>
// //                         <div className="form-row">
// //                             <div className="form-group left-align">
// //                                 <label>Select Departure Date</label>
// //                                 <input type="date" name="dateDeparture" value={formData.dateDeparture} onChange={handleInputChange} required />
// //                             </div>
// //                             <div className="form-group right-align">
// //                                 <label>Select Return Date</label>
// //                                 <input type="date" name="dateReturn" value={formData.dateReturn} onChange={handleInputChange} required />
// //                             </div>
// //                         </div>
// //                         <div className="form-row">
// //                             <div className="form-group left-align">
// //                                 <label>Departure City</label>
// //                                 <select name="departureCity" value={formData.departureCity} onChange={handleInputChange}>
// //                                     <option value="JOHANNESBURG">Johannesburg</option>
// //                                     <option value="CAPE TOWN">Cape Town</option>
// //                                     <option value="DURBAN">Durban</option>
// //                                 </select>
// //                             </div>
// //                             <div className="form-group right-align">
// //                                 <label>Select Flight Class</label>
// //                                 <select name="flightCabin" value={formData.flightCabin} onChange={handleInputChange}>
// //                                     <option value="Cheapest">Cheapest</option>
// //                                     <option value="Business">Business</option>
// //                                     <option value="Economy">Economy</option>
// //                                 </select>
// //                             </div>
// //                         </div>
// //                         <div className="form-row">
// //                             <div className="form-group left-align">
// //                                 <label>Number of Adults</label>
// //                                 <input type="number" name="nbrAdults" value={formData.nbrAdults} onChange={handleInputChange} min="1" max="6" />
// //                             </div>
// //                             <div className="form-group center-align">
// //                                 <label>Number of Children</label>
// //                                 <input type="number" name="nbrChildren" value={formData.nbrChildren} onChange={handleInputChange} min="0" max="4" />
// //                             </div>
// //                             <div className="form-group right-align">
// //                                 <label>Number of Infants</label>
// //                                 <input type="number" name="nbrInfants" value={formData.nbrInfants} onChange={handleInputChange} min="0" max="2" />
// //                             </div>
// //                         </div>
// //                         <button type="submit" disabled={loading}>
// //                             {loading ? 'Loading...' : 'Get Quote'}
// //                         </button>
// //                     </form>
// //                 )}
// //                 {loading && <p>Loading room options...</p>}
// //                 </>
// //             )}

// //             {isGalleryOpen && (
// //                 <div className="gallery-popup">
// //                     <div className="gallery-overlay" onClick={closeGallery}></div>
// //                     <div className="gallery-content">
// //                         {selectedImages.map((image, imgIndex) => (
// //                             <img key={imgIndex} src={image.imageURL} alt={`Gallery image ${imgIndex + 1}`} />
// //                         ))}
// //                         <button className="close-button" onClick={closeGallery}>Close</button>
// //                     </div>
// //                 </div>
// //             )}

// //             {showTandCModal && selectedPackage && (
// //                 <>
// //                     <div className="tandc-overlay" onClick={closeTandCModal}></div>
// //                     <div className="tandc-modal">
// //                         <div className="tandc-content">
// //                             <h3>Selected Package Details</h3>
// //                             <p><strong>Package:</strong> {selectedPackage.packageDesc}</p>
// //                             <p><strong>Total Price (ZAR):</strong> {formatCurrency(selectedPackage.packagePriceZARFrom)}</p>
// //                             <p><strong>Price per Person (ZAR):</strong> {formatCurrency(selectedPackage.pricePerPersonZARFrom)}</p>

// //                             <div className="quote-wording">
// //                                 <p>{quoteWording}</p>
// //                             </div>

// //                             <h4>Terms & Conditions</h4>
// //                             <div className="terms-scrollable" style={{whiteSpace:'pre-wrap', wordBreak:'break-word'}}>
// //                                 {termsAndConditions}
// //                             </div>

// //                             <div style={{marginTop:'20px', display:'flex', alignItems:'center'}}>
// //                                 <input 
// //                                     type="checkbox" 
// //                                     checked={termsChecked} 
// //                                     onChange={(e)=>setTermsChecked(e.target.checked)} 
// //                                     style={{marginRight:'8px'}}
// //                                 />
// //                                 <span>I hereby accept the terms and conditions, and understand them.</span>
// //                             </div>

// //                             <button 
// //                                 className="confirm-button" 
// //                                 onClick={handleAcceptTerms}
// //                                 disabled={!termsChecked}
// //                                 style={{marginTop:'20px'}}
// //                             >
// //                                 Accept & Confirm
// //                             </button>
// //                         </div>
// //                     </div>
// //                 </>
// //             )}

// //             {showDetailsForm && (
// //                 <div className="details-form-container" style={{width:'70%', margin:'0 auto'}}>
// //                     <h3>Please Provide Your Details</h3>
// //                     <form onSubmit={handleDetailsSubmit} className="details-form">
// //                         <div className="form-group">
// //                             <label>Full Name (As per Passport) *</label>
// //                             <input 
// //                                 type="text" 
// //                                 name="fullName" 
// //                                 value={detailsFormData.fullName} 
// //                                 onChange={handleDetailsFormChange} 
// //                                 required
// //                             />
// //                         </div>
// //                         <div className="form-group">
// //                             <label>Email Address *</label>
// //                             <input 
// //                                 type="email" 
// //                                 name="email" 
// //                                 value={detailsFormData.email} 
// //                                 onChange={handleDetailsFormChange}
// //                                 required 
// //                             />
// //                         </div>
// //                         <div className="form-group">
// //                             <label>Mobile Number (with International Dialing Code) *</label>
// //                             <input 
// //                                 type="text" 
// //                                 name="mobileNumber" 
// //                                 value={detailsFormData.mobileNumber} 
// //                                 onChange={handleDetailsFormChange}
// //                                 required 
// //                             />
// //                         </div>
// //                         <div className="form-group">
// //                             <label>Billing Name (Optional)</label>
// //                             <input 
// //                                 type="text" 
// //                                 name="billingName" 
// //                                 value={detailsFormData.billingName} 
// //                                 onChange={handleDetailsFormChange} 
// //                             />
// //                         </div>
// //                         <div className="form-group">
// //                             <label>Billing Address *</label>
// //                             <input 
// //                                 type="text" 
// //                                 name="billingAddress" 
// //                                 value={detailsFormData.billingAddress} 
// //                                 onChange={handleDetailsFormChange}
// //                                 required 
// //                             />
// //                         </div>
// //                         <button type="submit" className="confirm-button">Confirm</button>
// //                     </form>
// //                 </div>
// //             )}

// //             <footer className="footer">
// //                 {/* © 2024 Mauritius Dream. All rights reserved. */}
// //             </footer>
// //         </div>
// //     );
// // };

// // export default BookingForm;
// import React, { useState } from 'react';
// import './BookingForm.css';

// const BookingForm = () => {
//     const [formData, setFormData] = useState({
//         hotelCode: 'MAU',
//         dateDeparture: '',
//         dateReturn: '',
//         departureCity: 'JOHANNESBURG',
//         includeAir: true,
//         flightAdvanced: 'All Airlines',
//         flightCabin: 'Cheapest',
//         honeymoonRates: false,
//         repeaterRates: false,
//         weddingRates: false,
//         nbrAdults: '2',
//         nbrChildren: '0',
//         nbrInfants: '0'
//     });

//     const [apiResponse, setApiResponse] = useState(null);
//     const [selectedImages, setSelectedImages] = useState([]);
//     const [isGalleryOpen, setGalleryOpen] = useState(false);
//     const [loading, setLoading] = useState(false); 
//     const [showTandCModal, setShowTandCModal] = useState(false);
//     const [selectedPackage, setSelectedPackage] = useState(null);

//     const [acceptedTerms, setAcceptedTerms] = useState(false);
//     const [showDetailsForm, setShowDetailsForm] = useState(false);

//     const [detailsFormData, setDetailsFormData] = useState({
//         fullName: '',
//         email: '',
//         mobileNumber: '',
//         billingName: '',
//         billingAddress: ''
//     });

//     const [termsChecked, setTermsChecked] = useState(false);

//     const handleInputChange = (e) => {
//         const { name, value, type, checked } = e.target;
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: type === 'checkbox' ? checked : value
//         }));
//     };

//     const handleFormSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);
//         setApiResponse(null);

//         const payload = {
//             ...formData,
//             dateDeparture: new Date(formData.dateDeparture).toISOString(),
//             dateReturn: new Date(formData.dateReturn).toISOString(),
//         };
        
//         try {
//             const response = await fetch('/api/getQuote', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'BeachcomberKey': 'YOUR_API_KEY_HERE'
//                 },
//                 body: JSON.stringify(payload)
//             });

//             if (!response.ok) {
//                 throw new Error('Failed to fetch room options');
//             }

//             const data = await response.json();
//             setApiResponse(data);
//         } catch (error) {
//             console.error('Error during API request:', error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleImageClick = (images) => {
//         setSelectedImages(images);
//         setGalleryOpen(true);
//     };

//     const closeGallery = () => {
//         setGalleryOpen(false);
//     };

//     const handleGetAnotherQuote = () => {
//         setApiResponse(null);
//         setFormData({
//             hotelCode: 'MAU',
//             dateDeparture: '',
//             dateReturn: '',
//             departureCity: 'JOHANNESBURG',
//             includeAir: true,
//             flightAdvanced: 'All Airlines',
//             flightCabin: 'Cheapest',
//             honeymoonRates: false,
//             repeaterRates: false,
//             weddingRates: false,
//             nbrAdults: '2',
//             nbrChildren: '0',
//             nbrInfants: '0'
//         });
//     };

//     const formatCurrency = (amount) => {
//         return `R${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;
//     };

//     const termsAndConditions = `
// For foreigners:
// ...full terms here...
//     `;

//     const quoteWording = `
// Welcome to the Mauritian Dream and thank you for choosing us as your travel partner. 
// ...rest of quote wording here...
//     `;

//     const handleSelectPackage = (pkg) => {
//         setSelectedPackage(pkg);
//         setShowTandCModal(true);
//     };

//     const closeTandCModal = () => {
//         setShowTandCModal(false);
//         setSelectedPackage(null);
//         setTermsChecked(false);
//     };

//     const handleAcceptTerms = () => {
//         if (!termsChecked) return;
//         setAcceptedTerms(true);
//         setShowTandCModal(false);
//         setShowDetailsForm(true);
//     };

//     const handleDetailsFormChange = (e) => {
//         const { name, value } = e.target;
//         setDetailsFormData((prev) => ({ ...prev, [name]: value }));
//     };

//     const handleDetailsSubmit = async (e) => {
//         e.preventDefault();

//         const { fullName, email, mobileNumber, billingAddress } = detailsFormData;
//         if (!fullName || !email || !mobileNumber || !billingAddress) {
//             alert('Please fill in all required fields.');
//             return;
//         }

//         const emailData = {
//             fullName: detailsFormData.fullName,
//             email: detailsFormData.email,
//             mobileNumber: detailsFormData.mobileNumber,
//             billingName: detailsFormData.billingName,
//             billingAddress: detailsFormData.billingAddress,
//             selectedPackage,
//             termsAndConditions,
//             quoteWording,
//             hotelName: apiResponse?.hotelName || '',
//             dateDeparture: formData.dateDeparture,
//             dateReturn: formData.dateReturn,
//             flightCabin: formData.flightCabin,
//             nbrAdults: formData.nbrAdults,
//             nbrChildren: formData.nbrChildren,
//             nbrInfants: formData.nbrInfants,
//             departureCity: formData.departureCity
//         };

//         console.log('Email Data before sending:', emailData);

//         try {
//             const response = await fetch('/api/sendBookingEmail', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify(emailData)
//             });

//             if (!response.ok) {
//                 throw new Error('Failed to send booking email');
//             }

//             alert('Your booking details have been sent successfully!');
//             setShowDetailsForm(false);
//             setSelectedPackage(null);
//             setAcceptedTerms(false);
//             setApiResponse(null);
//         } catch (error) {
//             console.error('Error sending booking email:', error);
//             alert('There was an error sending your details. Please try again later.');
//         }
//     };

//     return (
//         <div className="booking-container">
//             <div className="info-boxes">
//                 <div className="info-box" onClick={() => window.location.href = '/about'}>
//                     <img src="/box1.png" alt="Box 1" className="box-image" />
//                     <h3>About Us</h3>
//                 </div>
//                 <div className="info-box" onClick={() => window.location.href = '/gallery'}>
//                     <img src="/box2.png" alt="Box 2" className="box-image" />
//                     <h3>Gallery</h3>
//                 </div>
//                 <div className="info-box" onClick={() => window.location.href = '/contact'}>
//                     <img src="/box3.png" alt="Box 3" className="box-image" />
//                     <h3>Contact Us</h3>
//                 </div>
//             </div>

//             {!acceptedTerms && !showDetailsForm && (
//                 <>
//                 {apiResponse ? (
//                     apiResponse.errorMsg ? (
//                         <div className="no-availability">
//                             <h3>Unfortunately, there is no availability at this time for your selected dates at the hotel.</h3>
//                             <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
//                                 Get Another Quote
//                             </button>
//                         </div>
//                     ) : (
//                         <>
//                             <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
//                                 Get Another Quote
//                             </button>

//                             <div className="hotel-overview">
//                                 <h3>{apiResponse.hotelName}</h3>
//                                 <p>{apiResponse.hotelDescription}</p>
//                                 <div className="hotel-gallery">
//                                     {apiResponse.hotelImages?.map((image, imgIndex) => (
//                                         <img key={imgIndex} className="thumbnail" src={image.imageURL} alt={`Hotel image ${imgIndex + 1}`} />
//                                     ))}
//                                 </div>
//                             </div>

//                             {apiResponse.roomOptions && apiResponse.roomOptions.length > 0 && (
//                                 apiResponse.roomOptions.map((room, index) => (
//                                     <div key={index} className="room-box">
//                                         <div className="room-description">
//                                             <h5>{room.accomProductName}</h5>
//                                             <p>{room.accomProdDescription}</p>
//                                             <h6>Packages Available:</h6>
//                                             {room.packages?.map((pkg, pkgIndex) => (
//                                                 <div key={pkgIndex} className="package-box">
//                                                     <p><strong>Package:</strong> {pkg.packageDesc}</p>
//                                                     <p><strong>Total Price (ZAR):</strong> {formatCurrency(pkg.packagePriceZARFrom)}</p>
//                                                     <p><strong>Price per Person (ZAR):</strong> {formatCurrency(pkg.pricePerPersonZARFrom)}</p>
//                                                     <button className="select-package-btn" onClick={() => handleSelectPackage(pkg)}>
//                                                         Select Package
//                                                     </button>
//                                                 </div>
//                                             ))}
//                                         </div>
//                                         <div>
//                                             <img className="room-image" src={room.productImages[0].imageURL} alt={`Room image ${index + 1}`} />
//                                         </div>
//                                     </div>
//                                 ))
//                             )}

//                             {apiResponse.airGDS && apiResponse.airGDS.length > 0 && (
//                                 apiResponse.airGDS.map((flight, flightIndex) => (
//                                     <div key={flightIndex} className="flight-box">
//                                         <p><strong>From:</strong> {flight.fromArpt} ({flight.fromArptCode})</p>
//                                         <p><strong>To:</strong> {flight.toArpt} ({flight.toArptCode})</p>
//                                         <p><strong>Carrier:</strong> {flight.carrier}</p>
//                                         <p><strong>Departure:</strong> {new Date(flight.depDateTime).toLocaleString()}</p>
//                                         <p><strong>Arrival:</strong> {new Date(flight.arrDateTime).toLocaleString()}</p>
//                                     </div>
//                                 ))
//                             )}

//                             {apiResponse.inclusions && apiResponse.inclusions.length > 0 && (
//                                 <div className="inclusions-section">
//                                     <h6>Inclusions:</h6>
//                                     <ul>
//                                         {apiResponse.inclusions.map((inclusion, idx) => (
//                                             <li key={idx}>{inclusion}</li>
//                                         ))}
//                                     </ul>
//                                 </div>
//                             )}
//                         </>
//                     )
//                 ) : (
//                     <form onSubmit={handleFormSubmit} className="booking-form">
//                         <div className="form-row">
//                             <div className="form-group full-width">
//                                 <label>Choose your Hotel</label>
//                                 <select name="hotelCode" value={formData.hotelCode} onChange={handleInputChange}>
//                                     <option value="MAU">Mauricia Beachcomber</option>
//                                     <option value="CAN">Cannonier Beachcomber</option>
//                                     <option value="VIC">Victoria Beachcomber</option>
//                                     <option value="PAR">Paradis Beachcomber</option>
//                                     <option value="SHA">Shandrani Beachcomber</option>
//                                     <option value="DIN">Dinarobin Beachcomber</option>
//                                     <option value="TAB">Troux Au Biches Beachcomber</option>
//                                     <option value="RP">Royal Palm Beachcomber</option>
//                                 </select>
//                             </div>
//                         </div>
//                         <div className="form-row">
//                             <div className="form-group left-align">
//                                 <label>Select Departure Date</label>
//                                 <input type="date" name="dateDeparture" value={formData.dateDeparture} onChange={handleInputChange} required />
//                             </div>
//                             <div className="form-group right-align">
//                                 <label>Select Return Date</label>
//                                 <input type="date" name="dateReturn" value={formData.dateReturn} onChange={handleInputChange} required />
//                             </div>
//                         </div>
//                         <div className="form-row">
//                             <div className="form-group left-align">
//                                 <label>Departure City</label>
//                                 <select name="departureCity" value={formData.departureCity} onChange={handleInputChange}>
//                                     <option value="JOHANNESBURG">Johannesburg</option>
//                                     <option value="CAPE TOWN">Cape Town</option>
//                                     <option value="DURBAN">Durban</option>
//                                 </select>
//                             </div>
//                             <div className="form-group right-align">
//                                 <label>Select Flight Class</label>
//                                 <select name="flightCabin" value={formData.flightCabin} onChange={handleInputChange}>
//                                     <option value="Cheapest">Cheapest</option>
//                                     <option value="Business">Business</option>
//                                     <option value="Economy">Economy</option>
//                                 </select>
//                             </div>
//                         </div>
//                         <div className="form-row">
//                             <div className="form-group left-align">
//                                 <label>Number of Adults</label>
//                                 <input type="number" name="nbrAdults" value={formData.nbrAdults} onChange={handleInputChange} min="1" max="6" />
//                             </div>
//                             <div className="form-group center-align">
//                                 <label>Number of Children</label>
//                                 <input type="number" name="nbrChildren" value={formData.nbrChildren} onChange={handleInputChange} min="0" max="4" />
//                             </div>
//                             <div className="form-group right-align">
//                                 <label>Number of Infants</label>
//                                 <input type="number" name="nbrInfants" value={formData.nbrInfants} onChange={handleInputChange} min="0" max="2" />
//                             </div>
//                         </div>
//                         <button type="submit" disabled={loading}>
//                             {loading ? 'Loading...' : 'Get Quote'}
//                         </button>
//                     </form>
//                 )}
//                 {loading && <p>Loading room options...</p>}
//                 </>
//             )}

//             {isGalleryOpen && (
//                 <div className="gallery-popup">
//                     <div className="gallery-overlay" onClick={closeGallery}></div>
//                     <div className="gallery-content">
//                         {selectedImages.map((image, imgIndex) => (
//                             <img key={imgIndex} src={image.imageURL} alt={`Gallery image ${imgIndex + 1}`} />
//                         ))}
//                         <button className="close-button" onClick={closeGallery}>Close</button>
//                     </div>
//                 </div>
//             )}

//             {showTandCModal && selectedPackage && (
//                 <>
//                     <div className="tandc-overlay" onClick={closeTandCModal}></div>
//                     <div className="tandc-modal">
//                         <div className="tandc-content">
//                             <h3>Selected Package Details</h3>
//                             <p><strong>Package:</strong> {selectedPackage.packageDesc}</p>
//                             <p><strong>Total Price (ZAR):</strong> {formatCurrency(selectedPackage.packagePriceZARFrom)}</p>
//                             <p><strong>Price per Person (ZAR):</strong> {formatCurrency(selectedPackage.pricePerPersonZARFrom)}</p>

//                             <div className="quote-wording">
//                                 <p>{quoteWording}</p>
//                             </div>

//                             <h4>Terms & Conditions</h4>
//                             <div className="terms-scrollable" style={{whiteSpace:'pre-wrap', wordBreak:'break-word'}}>
//                                 {termsAndConditions}
//                             </div>

//                             <div style={{marginTop:'20px', display:'flex', alignItems:'center'}}>
//                                 <input 
//                                     type="checkbox" 
//                                     checked={termsChecked} 
//                                     onChange={(e)=>setTermsChecked(e.target.checked)} 
//                                     style={{marginRight:'8px'}}
//                                 />
//                                 <span>I hereby accept the terms and conditions, and understand them.</span>
//                             </div>

//                             <button 
//                                 className="confirm-button" 
//                                 onClick={handleAcceptTerms}
//                                 disabled={!termsChecked}
//                                 style={{marginTop:'20px'}}
//                             >
//                                 Accept & Confirm
//                             </button>
//                         </div>
//                     </div>
//                 </>
//             )}

//             {showDetailsForm && (
//                 <div className="details-form-container" style={{width:'70%', margin:'0 auto'}}>
//                     <h3>Please Provide Your Details</h3>
//                     <form onSubmit={handleDetailsSubmit} className="details-form">
//                         <div className="form-group">
//                             <label>Full Name (As per Passport) *</label>
//                             <input 
//                                 type="text" 
//                                 name="fullName" 
//                                 value={detailsFormData.fullName} 
//                                 onChange={handleDetailsFormChange} 
//                                 required
//                             />
//                         </div>
//                         <div className="form-group">
//                             <label>Email Address *</label>
//                             <input 
//                                 type="email" 
//                                 name="email" 
//                                 value={detailsFormData.email} 
//                                 onChange={handleDetailsFormChange}
//                                 required 
//                             />
//                         </div>
//                         <div className="form-group">
//                             <label>Mobile Number (with International Dialing Code) *</label>
//                             <input 
//                                 type="text" 
//                                 name="mobileNumber" 
//                                 value={detailsFormData.mobileNumber} 
//                                 onChange={handleDetailsFormChange}
//                                 required 
//                             />
//                         </div>
//                         <div className="form-group">
//                             <label>Billing Name (Optional)</label>
//                             <input 
//                                 type="text" 
//                                 name="billingName" 
//                                 value={detailsFormData.billingName} 
//                                 onChange={handleDetailsFormChange} 
//                             />
//                         </div>
//                         <div className="form-group">
//                             <label>Billing Address *</label>
//                             <input 
//                                 type="text" 
//                                 name="billingAddress" 
//                                 value={detailsFormData.billingAddress} 
//                                 onChange={handleDetailsFormChange}
//                                 required 
//                             />
//                         </div>
//                         <button type="submit" className="confirm-button">Confirm</button>
//                     </form>
//                 </div>
//             )}

//             <footer className="footer">
//                 {/* © 2024 Mauritius Dream. All rights reserved. */}
//             </footer>
//         </div>
//     );
// };

// export default BookingForm;
import React, { useState } from 'react';
import './BookingForm.css';

const BookingForm = () => {
    const [formData, setFormData] = useState({
        hotelCode: 'MAU',
        dateDeparture: '',
        dateReturn: '',
        departureCity: 'JOHANNESBURG',
        includeAir: true,
        flightAdvanced: 'All Airlines',
        flightCabin: 'Cheapest',
        honeymoonRates: false,
        repeaterRates: false,
        weddingRates: false,
        nbrAdults: '2',
        nbrChildren: '0',
        nbrInfants: '0'
    });

    const [apiResponse, setApiResponse] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [isGalleryOpen, setGalleryOpen] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [showTandCModal, setShowTandCModal] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [showDetailsForm, setShowDetailsForm] = useState(false);

    const [detailsFormData, setDetailsFormData] = useState({
        fullName: '',
        email: '',
        mobileNumber: '',
        billingName: '',
        billingAddress: ''
    });

    const [termsChecked, setTermsChecked] = useState(false);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setApiResponse(null);

        const payload = {
            ...formData,
            dateDeparture: new Date(formData.dateDeparture).toISOString(),
            dateReturn: new Date(formData.dateReturn).toISOString(),
        };
        
        try {
            const response = await fetch('/api/getQuote', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'BeachcomberKey': 'YOUR_API_KEY_HERE'
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error('Failed to fetch room options');
            }

            const data = await response.json();
            setApiResponse(data);
        } catch (error) {
            console.error('Error during API request:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleImageClick = (images) => {
        setSelectedImages(images);
        setGalleryOpen(true);
    };

    const closeGallery = () => {
        setGalleryOpen(false);
    };

    const handleGetAnotherQuote = () => {
        setApiResponse(null);
        setFormData({
            hotelCode: 'MAU',
            dateDeparture: '',
            dateReturn: '',
            departureCity: 'JOHANNESBURG',
            includeAir: true,
            flightAdvanced: 'All Airlines',
            flightCabin: 'Cheapest',
            honeymoonRates: false,
            repeaterRates: false,
            weddingRates: false,
            nbrAdults: '2',
            nbrChildren: '0',
            nbrInfants: '0'
        });
    };

    const formatCurrency = (amount) => {
        return `R${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`;
    };

    // Full Terms and Conditions text (no omissions)
    const termsAndConditions = `
For foreigners:

While we strive to always provide the latest information and details about our product offerings on this website, please be sure to check with the hotel on any specifics that may be important to you. Information presented here is subject to change. Contents of this website only apply to Beachcomber Resorts & Hotels in Mauritius.

Room Disclaimer:
Beachcomber carries out regular improvements to its existing accommodation and therefore room facilities or room layout of the reserved room may be different from the displayed photo.

Check in Check out:
Check-in: 2 p.m.
Check-out: noon
For an early check-in or late check-out after 6.00 p.m., one full night will be charged.
Rate charged will be based on meal plan booked and offers.
For a late check-out until 6.00 p.m. (on request), 50% of the applicable rate will be charged – except for Royal Palm Beachcomber Luxury, where one full night will be charged for a pre-bookings and late check-outs.

For nights falling between 20 December 2024 and 8 January 2025
One full night will be charged for pre-bookings and late check-outs 
For Villas at Dinarobin Beachcomber, Paradis Beachcomber and Trou aux Biches Beachcomber, early check-in and late check-out are granted without any supplement

Honeymoon Policy:
Valid 12 months as from the wedding date
Your wedding certificate will be requested at the time of check-in
Should you fail to produce your valid wedding certificate upon arrival, an extra cost will be charged directly at the hotel

Wedding Anniversary Policy:
The anniversary date should be within 6 months prior to the date of arrival or 6 months after the date of arrival
Offer applicable to every 5th wedding anniversary
Your wedding certificate will be requested at the time of check-in
Should you fail to produce your valid wedding certificate upon arrival, an extra cost will be charged directly at the hotel

Payment Policy:
Once you have made the reservation with us, you will recieve an email confirmation, and then once confirmed you will be sent a payment link for payment to secure your booking. 

Kindly note that we currently accept the following payment methods.
in EURO: VISA, MASTERCARD, AMERICAN EXPRESS
in USD: VISA, MASTERCARD
in MUR: VISA, MASTERCARD

In order to secure online payments in EURO, the beachcomber hotels website uses the internationally recognized PAYBOX secure payment system, which incorporates the SSL (Secure Locket Layer) security protocol.

The PAYBOX payment interface also makes use of 3-D-Secure technology. Designed by Visa and MasterCard, this system authenticates cardholders and prevents fraudulent use of their bank cards. The 3-D-Secure system automatically redirects you to a bank interface where a code or personal piece of information provided separately by the bank must be entered after the card number, expiry date and cryptogram have been provided. Once authenticated, the transaction is then either authorized or declined by the bank.

In order to secure online payments in USD, AUD, GBP and MUR, the Beachcomber Hotels website uses MIPS secure payment system, which incorporates the SSL (Secure Locket Layer) security protocol.
All confidential data (16-digit card number, expiry date and cryptogram) are transmitted directly in encrypted format to the payment gateway servers (of PayBox and MIPS) without ever passing through any physical device within the Beachcomber Hotels server system.

Once the bank details are validated, the secure payment management systems send an authorization request to the bank card network. 
The remote payment system then delivers an electronic certificate. In accordance with the provisions of articles 1316 et seq. of the French Civil Code, the electronic certificate shall serve as proof of the transaction date and amount and will be kept in the proper archives set up by Beachcomber Hotels. Dates and times contained on the server shall serve as official records for the parties.

Kindly note that making an online payment implies that you are fully authorized to use the bank card provided for payment and that you have sufficient funds to cover the total cost of the transaction.

For all transactions, payment of the booking shall constitute signature and express acceptance of the sale with all that is included in the price.

Payment Conditions:
Beachcomber Resorts & Hotels will not accept cash payments in excess of 500,000 rupees or an equivalent amount in foreign currency in order to comply with Section 5(1) of the Financial Intelligence and Anti-Money Laundering Act 2002.

Payment and Cancellation Details:
All prices listed on this website are in EUR/ USD/ AUD/ GBP/ MUR/ZAR, unless specified otherwise. Promotions displayed on this website can be changed or withdrawn without prior notice. Blackout dates may apply.
Cancellations will not be processed unless we receive an official cancellation request by mail at bookings@beachcomber.com.
After you have cancelled a booking, you will receive an email to confirm your cancellation at the e-mail address you provided at the time of booking.
To prevent any fraud, refunds (when applicable) will only be made to the credit card used to secure the booking.

Year round EXCEPT from 20th December 2024 to 8th January 2025 inclusive (all hotels excluding villas at Dinarobin Beachcomber and Paradis Beachcomber)
Payment conditions:
A deposit of 50%* of the total amount due is required to confirm and secure your booking
The remaining balance should be settled directly at hotel upon arrival
In case of “Non-Refundable” offer, 100% of the total amount due will be required to confirm and secure your booking

Cancellations and amendments:
No cancellation fee for stays cancelled up to 8 days prior to arrival
Cancellations (full or partial) as from 7 days prior to arrival: 100% of the total amount will be charged
No show: 100% of the amount deposit will be charged
Unexpected departures: no refund for nights booked
“Non-Refundable” offer: no refund for nights booked

Cancellations and amendment for Royal Palm Beachcomber Luxury:
For Junior Suites, Tropical Suites, Ocean Suites only 
No cancellation fee for stays cancelled up to 8 days prior to arrival
7 days or less prior to arrival 100% cancellation fee on whole stay
No-Show 100% cancellation fee on whole stay
Unexpected departure 100% cancellation fee on the unused nights
Name changes will be accepted 7 days prior to arrival

For Palm Suites, Penthouse, Presidential Suites and Royal Villa only
21 days or less prior to arrival 100% cancellation fee on whole stay
No-Show 100% cancellation fee on whole stay
Unexpected departure 100% cancellation fee on the unused nights
Name changes will be accepted 7 days prior to arrival

Cancellation fees for 10 or more adults at Royal Palm Beachcomber Luxury:
Partial cancellation fees apply as per conditions stated above.

PRIME SEASON: from 20th December 2024 to 8th January 2025 inclusive.
Payment conditions:
A deposit of 50%* of the total amount due is required to confirm and secure your booking
The remaining balance should be settled by 15th November
*Conditions apply depending on the country of residence.

Cancellation and no-show policy (20th December 2024 to 8th January 2025)
All hotels excluding Paradis Beachcomber and Dinarobin Beachcomber Villas
45 to 31 days prior to arrival: 25% fee of the amount of the whole stay will be charged
30 days or less to arrival: 100% fee of the amount of the whole stay will be charged
No show: 100% fee of the amount of the whole stay will be charged
Unexpected departure: 100% fee on the unused nights

Applicable procedures in case of flight delays/cancellations due to unforeseen circumstances (Including Cyclones)
No show
In case of “no show” The Company will charge the applicable cancellation fees (depending on the seasonality).

Clients arriving on delayed flights but leaving on scheduled date of departure :
The hotel will refund the ‘unused nights’.

Clients arriving on delayed flights but spending the number of nights originally booked at the hotel (i.e. leaving later)
The hotel will charge for the whole stay with no compensation.

Clients arriving on scheduled date but extending their stay at the hotel due to non-operating flights :
Our guest will have the guarantee to be hosted in a Beachcomber Hotel (as per availability). If hotel offered is different from the initial hotel, transfer will be at client’s charge. The hotel will charge a flat Half-board rate for the extra nights (to be paid by TO or by client directly at hotel before departure).  Nightly flat rate will be as follows:

Royal Palm Beachcomber Luxury:
200 € single adult room, 
250€ double adult room, 
300 € triple adult room
185 € single child room
220 € double children room
Child sharing supplement (12-17 years old) : 35 € per child

5-star hotels ( Dinarobin Beachcomber, Paradis Beachcomber, Trou aux Biches Beachcomber)
150 € single adult room, 
200€ double adult room, 
250 € triple adult room
135 € single child room
170 € double children room
Child sharing supplement (12-17 years old) : 35 € per child

4-star hotels ( Shandrani Beachcomber, Victoria Beachcomber, Canonnier Beachcomber, Mauricia Beachcomber)
100 € single adult room, 
130€ double adult room, 
160 € triple adult room
95 € single child room
125 € double children room
Child sharing supplement (12-17 years old) : 25 € per child

All websites designed by and managed by Beachcomber Hotels may feature links to other sites operated by third parties. Beachcomber Hotels is not responsible for the privacy practices or the content of such web sites.

While every precaution has been taken, we cannot ensure that a third party will not unlawfully access private details and we advise you to take all necessary precautions and suggest that you do not permit others to access your personal codes or passwords.

Reservations:
Kindly note that prices displayed on our website are subject to change in the course of the season and may differ from prices published elsewhere. When using our website, you accept that prices published elsewhere may differ from yours at the time of booking.

Subject to our confirmation, and also to your provision of valid and correct credit card details for payment, your reservation will be irrevocably binding upon your clicking on “Confirm” on our website.

As we will send you a confirmation of your reservation by email, it is your duty to provide us with an accurate email address. Such confirmation will usually be made within 48 business hours after you submitted your reservation. If your reservation is not accepted, we will make every effort to provide you with a comparable alternative, which you are free to accept or decline. Our confirmation email will serve as proof of valid reservation and will contain relevant information about the reservation and particulars of the hotel concerned. If you do not receive such a confirmation, please notify us by e-mail at info@themauritiusdream.com.

Please read and check carefully all the information contained in our confirmation email to ensure that it conforms to your reservation. You are required to present our confirmation email when checking-in at the hotel, in addition to a valid passport.

Visa Requirements:
We suggest you inform yourself about the entry/visa requirements before making your reservation.
    `;

    const quoteWording = `
Welcome to the Mauritian Dream and thank you for choosing us as your travel partner. A consultant will be in contact with you soon to plan your dream holiday.

If you have any questions you are welcome to contact us via email at info@themauritiusdream.com or WhatsApp on +230 5483 7078 - Contact: Alicia Venter.

Please review the quote details below and our Terms and Conditions.
    `;

    const handleSelectPackage = (pkg) => {
        setSelectedPackage(pkg);
        setShowTandCModal(true);
    };

    const closeTandCModal = () => {
        setShowTandCModal(false);
        setSelectedPackage(null);
        setTermsChecked(false);
    };

    const handleAcceptTerms = () => {
        if (!termsChecked) return;
        setAcceptedTerms(true);
        setShowTandCModal(false);
        setShowDetailsForm(true);
    };

    const handleDetailsFormChange = (e) => {
        const { name, value } = e.target;
        setDetailsFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleDetailsSubmit = async (e) => {
        e.preventDefault();

        const { fullName, email, mobileNumber, billingAddress } = detailsFormData;
        if (!fullName || !email || !mobileNumber || !billingAddress) {
            alert('Please fill in all required fields.');
            return;
        }

        const emailData = {
            fullName: detailsFormData.fullName,
            email: detailsFormData.email,
            mobileNumber: detailsFormData.mobileNumber,
            billingName: detailsFormData.billingName,
            billingAddress: detailsFormData.billingAddress,
            selectedPackage,
            termsAndConditions,
            quoteWording,
            hotelName: apiResponse?.hotelName || '',
            dateDeparture: formData.dateDeparture,
            dateReturn: formData.dateReturn,
            flightCabin: formData.flightCabin,
            nbrAdults: formData.nbrAdults,
            nbrChildren: formData.nbrChildren,
            nbrInfants: formData.nbrInfants,
            departureCity: formData.departureCity
        };

        try {
            const response = await fetch('/api/sendBookingEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(emailData)
            });

            if (!response.ok) {
                throw new Error('Failed to send booking email');
            }

            alert('Your booking details have been sent successfully!');
            setShowDetailsForm(false);
            setSelectedPackage(null);
            setAcceptedTerms(false);
            setApiResponse(null);
        } catch (error) {
            console.error('Error sending booking email:', error);
            alert('There was an error sending your details. Please try again later.');
        }
    };

    return (
        <div className="booking-container">
            <div className="info-boxes">
                <div className="info-box" onClick={() => window.location.href = '/about'}>
                    <img src="/box1.png" alt="Box 1" className="box-image" />
                    <h3>About Us</h3>
                </div>
                <div className="info-box" onClick={() => window.location.href = '/gallery'}>
                    <img src="/box2.png" alt="Box 2" className="box-image" />
                    <h3>Gallery</h3>
                </div>
                <div className="info-box" onClick={() => window.location.href = '/hotels'}>
                    <img src="/box3.png" alt="Box 3" className="box-image" />
                    <h3>Hotels</h3>
                </div>
                <div className="info-box" onClick={() => window.location.href = '/contact'}>
                    <img src="/box3.png" alt="Box " className="box-image" />
                    <h3>Contact Us</h3>
                </div>
            </div>

            {!acceptedTerms && !showDetailsForm && (
                <>
                {apiResponse ? (
                    apiResponse.errorMsg ? (
                        <div className="no-availability">
                            <h3>Unfortunately, there is no availability at this time for your selected dates at the hotel.</h3>
                            <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
                                Get Another Quote
                            </button>
                        </div>
                    ) : (
                        <>
                            <button className="get-another-quote-btn" onClick={handleGetAnotherQuote}>
                                Get Another Quote
                            </button>

                            <div className="hotel-overview">
                                <h3>{apiResponse.hotelName}</h3>
                                <p>{apiResponse.hotelDescription}</p>
                                <div className="hotel-gallery">
                                    {apiResponse.hotelImages?.map((image, imgIndex) => (
                                        <img key={imgIndex} className="thumbnail" src={image.imageURL} alt={`Hotel image ${imgIndex + 1}`} />
                                    ))}
                                </div>
                            </div>

                            {apiResponse.roomOptions && apiResponse.roomOptions.length > 0 && (
                                apiResponse.roomOptions.map((room, index) => (
                                    <div key={index} className="room-box">
                                        <div className="room-description">
                                            <h5>{room.accomProductName}</h5>
                                            <p>{room.accomProdDescription}</p>
                                            <h6>Packages Available:</h6>
                                            {room.packages?.map((pkg, pkgIndex) => (
                                                <div key={pkgIndex} className="package-box">
                                                    <p><strong>Package:</strong> {pkg.packageDesc}</p>
                                                    <p><strong>Total Price (ZAR):</strong> {formatCurrency(pkg.packagePriceZARFrom)}</p>
                                                    <p><strong>Price per Person (ZAR):</strong> {formatCurrency(pkg.pricePerPersonZARFrom)}</p>
                                                    <button className="select-package-btn" onClick={() => handleSelectPackage(pkg)}>
                                                        Select Package
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                        <div>
                                            <img className="room-image" src={room.productImages[0].imageURL} alt={`Room image ${index + 1}`} />
                                        </div>
                                    </div>
                                ))
                            )}

                            {apiResponse.airGDS && apiResponse.airGDS.length > 0 && (
                                apiResponse.airGDS.map((flight, flightIndex) => (
                                    <div key={flightIndex} className="flight-box">
                                        <p><strong>From:</strong> {flight.fromArpt} ({flight.fromArptCode})</p>
                                        <p><strong>To:</strong> {flight.toArpt} ({flight.toArptCode})</p>
                                        <p><strong>Carrier:</strong> {flight.carrier}</p>
                                        <p><strong>Departure:</strong> {new Date(flight.depDateTime).toLocaleString()}</p>
                                        <p><strong>Arrival:</strong> {new Date(flight.arrDateTime).toLocaleString()}</p>
                                    </div>
                                ))
                            )}

                            {apiResponse.inclusions && apiResponse.inclusions.length > 0 && (
                                <div className="inclusions-section">
                                    <h6>Inclusions:</h6>
                                    <ul>
                                        {apiResponse.inclusions.map((inclusion, idx) => (
                                            <li key={idx}>{inclusion}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    )
                ) : (
                    <form onSubmit={handleFormSubmit} className="booking-form">
                        <div className="form-row">
                            <div className="form-group full-width">
                                <label>Choose your Hotel</label>
                                <select name="hotelCode" value={formData.hotelCode} onChange={handleInputChange}>
                                    <option value="MAU">Mauricia Beachcomber</option>
                                    <option value="CAN">Cannonier Beachcomber</option>
                                    <option value="VIC">Victoria Beachcomber</option>
                                    <option value="PAR">Paradis Beachcomber</option>
                                    <option value="SHA">Shandrani Beachcomber</option>
                                    <option value="DIN">Dinarobin Beachcomber</option>
                                    <option value="TAB">Troux Au Biches Beachcomber</option>
                                    <option value="RP">Royal Palm Beachcomber</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group left-align">
                                <label>Select Departure Date</label>
                                <input type="date" name="dateDeparture" value={formData.dateDeparture} onChange={handleInputChange} required />
                            </div>
                            <div className="form-group right-align">
                                <label>Select Return Date</label>
                                <input type="date" name="dateReturn" value={formData.dateReturn} onChange={handleInputChange} required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group left-align">
                                <label>Departure City</label>
                                <select name="departureCity" value={formData.departureCity} onChange={handleInputChange}>
                                    <option value="JOHANNESBURG">Johannesburg</option>
                                    <option value="CAPE TOWN">Cape Town</option>
                                    <option value="DURBAN">Durban</option>
                                </select>
                            </div>
                            <div className="form-group right-align">
                                <label>Select Flight Class</label>
                                <select name="flightCabin" value={formData.flightCabin} onChange={handleInputChange}>
                                    <option value="Cheapest">Cheapest</option>
                                    <option value="Business">Business</option>
                                    <option value="Economy">Economy</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group left-align">
                                <label>Number of Adults</label>
                                <input type="number" name="nbrAdults" value={formData.nbrAdults} onChange={handleInputChange} min="1" max="6" />
                            </div>
                            <div className="form-group center-align">
                                <label>Number of Children</label>
                                <input type="number" name="nbrChildren" value={formData.nbrChildren} onChange={handleInputChange} min="0" max="4" />
                            </div>
                            <div className="form-group right-align">
                                <label>Number of Infants</label>
                                <input type="number" name="nbrInfants" value={formData.nbrInfants} onChange={handleInputChange} min="0" max="2" />
                            </div>
                        </div>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Loading...' : 'Get Quote'}
                        </button>
                    </form>
                )}
                {loading && <p>Loading room options...</p>}
                </>
            )}

            {isGalleryOpen && (
                <div className="gallery-popup">
                    <div className="gallery-overlay" onClick={closeGallery}></div>
                    <div className="gallery-content">
                        {selectedImages.map((image, imgIndex) => (
                            <img key={imgIndex} src={image.imageURL} alt={`Gallery image ${imgIndex + 1}`} />
                        ))}
                        <button className="close-button" onClick={closeGallery}>Close</button>
                    </div>
                </div>
            )}

            {showTandCModal && selectedPackage && (
                <>
                    <div className="tandc-overlay" onClick={closeTandCModal}></div>
                    <div className="tandc-modal">
                        <div className="tandc-content">
                            <h3>Selected Package Details</h3>
                            <p><strong>Package:</strong> {selectedPackage.packageDesc}</p>
                            <p><strong>Total Price (ZAR):</strong> {formatCurrency(selectedPackage.packagePriceZARFrom)}</p>
                            <p><strong>Price per Person (ZAR):</strong> {formatCurrency(selectedPackage.pricePerPersonZARFrom)}</p>

                            <div className="quote-wording">
                                <p>{quoteWording}</p>
                            </div>

                            <h4>Terms & Conditions</h4>
                            <div className="terms-scrollable" style={{whiteSpace:'pre-wrap', wordBreak:'break-word'}}>
                                {termsAndConditions}
                            </div>

                            <div style={{marginTop:'20px'}}>
                                <label style={{ display:'flex', alignItems:'center', cursor:'pointer' }}>
                                    <input 
                                        type="checkbox" 
                                        checked={termsChecked} 
                                        onChange={(e)=>setTermsChecked(e.target.checked)} 
                                        style={{marginRight:'8px'}}
                                    />
                                    I hereby accept the terms and conditions, and understand them.
                                </label>
                            </div>

                            <button 
                                className="confirm-button" 
                                onClick={handleAcceptTerms}
                                disabled={!termsChecked}
                                style={{marginTop:'20px'}}
                            >
                                Accept & Confirm
                            </button>
                        </div>
                    </div>
                </>
            )}

            {showDetailsForm && (
                <div className="details-form-container" style={{width:'70%', margin:'0 auto'}}>
                    <h3>Please Provide Your Details</h3>
                    <form onSubmit={handleDetailsSubmit} className="details-form">
                        <div className="form-group">
                            <label>Full Name (As per Passport) *</label>
                            <input 
                                type="text" 
                                name="fullName" 
                                value={detailsFormData.fullName} 
                                onChange={handleDetailsFormChange} 
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Email Address *</label>
                            <input 
                                type="email" 
                                name="email" 
                                value={detailsFormData.email} 
                                onChange={handleDetailsFormChange}
                                required 
                            />
                        </div>
                        <div className="form-group">
                            <label>Mobile Number (with International Dialing Code) *</label>
                            <input 
                                type="text" 
                                name="mobileNumber" 
                                value={detailsFormData.mobileNumber} 
                                onChange={handleDetailsFormChange}
                                required 
                            />
                        </div>
                        <div className="form-group">
                            <label>Billing Name (Optional)</label>
                            <input 
                                type="text" 
                                name="billingName" 
                                value={detailsFormData.billingName} 
                                onChange={handleDetailsFormChange} 
                            />
                        </div>
                        <div className="form-group">
                            <label>Billing Address *</label>
                            <input 
                                type="text" 
                                name="billingAddress" 
                                value={detailsFormData.billingAddress} 
                                onChange={handleDetailsFormChange}
                                required 
                            />
                        </div>
                        <button type="submit" className="confirm-button">Confirm</button>
                    </form>
                </div>
            )}

            <footer className="footer">
                {/* © 2024 Mauritius Dream. All rights reserved. */}
            </footer>
        </div>
    );
};

export default BookingForm;
